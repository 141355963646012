<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>华为技术有限公司企业BG全球副总裁薛铭莅临心麦</h2>
            <span>2021-04-06</span>
            <div class="wrap-news-content">
                <p>4月5日下午，华为技术有限公司企业BG全球副总裁薛铭莅临心麦（江苏）进行考察，由心麦董事长胡玉霞、董事总裁缪建洪、技研中心总监鲍健明、产品中心总监杨帆陪同接待。</p>
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <span>{{item.tips ? item.tips : ''}}</span>
                    <p>{{item.summary ? item.summary : ''}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail1",
        data () {
            return {
                newsList: [
                    {
                        img: '001.jpg',
                        tips: '',
                        summary: '首先来到心麦视界，缪总就公司发展提出“1+N”战略（系列智能感知系统为1，多场景数字健康解决方案为N），介绍心麦各系列产品，缪总对各个落地场景（智慧城市公交管理场景、智慧健康校园管理、慢病监测体验区、适老化改造系统、睡眠管理系统）以及今后的发展规划做了相关介绍，双方并就此进行深入探讨。'
                    },
                    {
                        img: '001_1.jpg',
                        tips: '',
                        summary: '薛总提出一些相应的建议，并期待与心麦公司有进一步的合作。'
                    },
                    {
                        img: '001_2.jpg',
                        tips: '',
                        summary: '双方共同提出：依托于华为提供基础建设云平台，心麦开发上层应用服务。'
                    },
                    {
                        img: '001_3.jpg',
                        tips: '',
                        summary: '技研中心总监鲍建明、产品中心总监杨帆与华为公司副总裁薛铭进行技术上的友好交流。'
                    },
                    {
                        img: '001_4.jpg',
                        tips: '',
                        summary: '期待双方促进不断协作，共同提升企业综合实力，提高在行业中的地位，为促进智能健康事业做出更大的贡献。'
                    },
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
