<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/ygs.jpg" alt="">
        </div>
        <div  class="staff-presence">
            <div class="smart-title">
                <div class="smart-f-title">
                    <span>员工</span><span style="color: #ff4100">风采</span>
                </div>
                <span style="text-transform: uppercase">Staff presence</span>
            </div>
            <div class="wrap-presence-content">
                <ul>
                    <li v-for="(item,index) in presenceList" :key="index">
                        <div class="wrap-presence-img">
                            <img :src="require('../../assets/img/employee/'+item.img)" alt="">
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data () {
            return {
                presenceList: [
                    {
                        img: '1.jpg'
                    },
                    {
                        img: '2.jpg'
                    },
                    {
                        img: '3.jpg'
                    },
                    {
                        img: '4.jpg'
                    },
                    {
                        img: '5.jpg'
                    },
                    {
                        img: '6.jpg'
                    },
                    {
                        img: '7.jpg'
                    },
                    {
                        img: '8.jpg'
                    },
                    {
                        img: '9.jpg'
                    },
                    {
                        img: '10.jpg'
                    },
                    {
                        img: '11.jpg'
                    },
                    {
                        img: '12.jpg'
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
            this.$parent.toPage(4)
        },
    }
</script>

<style scoped>
    .banner img {
        width: 100%;
    }
    .staff-presence {
        padding: 60px 0;
    }
    .smart-title {
        width: 1440px;
        margin: 0 auto;
        padding: 40px 0;

    }
    .smart-title > span {
        font-size: 14px;
        color: #7F7F7F;
        letter-spacing: 0;
        display: block;
        margin-top: 10px;
    }
    .smart-f-title{
        font-size: 30px;
    }
    .wrap-presence-content {
        width: 1440px;
        margin: 0 auto;
    }
    .wrap-presence-content > ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .wrap-presence-content > ul li {
        min-width: 25%;
        max-width: 25%;
        margin-top: 30px;
    }
    .wrap-presence-img {
        margin: 20px;
        height: 200px;
    }
    .wrap-presence-img img {
        width: 100%;
    }
</style>
