<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/jjfa.png" alt="">
            <div class="mengban">
                <div>
                    <span>大健康产业解决方案</span>
                </div>
            </div>
        </div>
        <div class="solution">
            <h3>超过20万行业用户的最终选择</h3>
            <ul>
                <li v-for="(item,index) in solution" :key="index">
                    <div>
                        <img :src="require('../../assets/img/'+item.img)" alt="">
                        <span>{{item.name}}</span>
                    </div>
                    <span class="hover-info">
                        <span>
                            {{item.detail}}
                        </span>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data () {
            return {
                solution: [
                    {
                        name:'智慧养老解决方案',
                        img:'zhyl.jpg',
                        detail:'心麦结合数字化健康发展趋势，采用身联网技术，对老年人的健康指标通过只能感知设备实时监测统计分析。同时建立起健康医疗服务团队，为老人提供长期的、专业的、有效的健康管理解决方案。实现四位一体的关爱老人管理服务系统。'
                    },
                    {
                        name:'智慧校园解决方案',
                        img:'zhxy.png',
                        detail:'针对学生日常的校内运动风险、营养不良、肥胖、心理压力大等状况影响学生健康的关键因素，结合当下疫情防控，心麦研发了健康校园解决方案。'
                    },
                    {
                        name:'智慧城市公交方案',
                        img:'zhgj.png',
                        detail:'据统计每年都会发生车厢事故，因车厢事故赔偿金额较大，这些问题加大乘客与交产的社会化矛盾，针对这一现状，心麦在公交车安装蓝牙网关、为司机佩戴只能感知设备并提供监测管理平台，实现了将车辆、司机、乘客、管理者实时交互预警，有效解决了乘客迷失、走丢及车厢内摔倒的现状。'
                    },
                    {
                        name:'矿工健康安全解决方案',
                        img:'miner_healthy.jpg',
                        detail:'在煤矿选矿、开采等生产活动中，由于工作环境中存在大量的粉尘污染物,是目前职业病高发行业。心麦针对行业痛点有了一系列的解决方案，其中不乏噪音监测、有害气体监测、矿工身体健康以及心理健康监测。'
                    },
                    {
                        name:'智能班牌方案',
                        img:'class_brand.jpg',
                        detail:'在疫情的大环境下，心麦智能班牌开发出特色测温功能键，以便随时获悉学生的体温，此外，该智能班牌还集成了时间播报、家庭作业播报、拨打电话以及一键呼救等功能。'
                    },
                    {
                        name:'智慧警务方案',
                        img:'zhjw.jpg',
                        detail:'心麦针对警务现有的人员定位不清晰、双向沟通不便捷等问题提供了相应的解决方案，通过硬件设备安心手表、华为手表实现对警员进行实时定位，并将准确的定位信息提供给管理人员。同时还能对警员的身体进行实时监测以及提供便捷有效的沟通方式。'
                    },
                    {
                        name:'老干部健康管理方案',
                        img:'lgb.jpg',
                        detail:'每个人都是一个独立的个体，每位年老干部的身体健康状况也各不相同。心麦所构想的新一代智慧体检，针对每个人不同的健康状况，在基础的体检项目上增加与其相关的额外体检项目，做到“千人千面”。'
                    },
                    {
                        name:'司机健康呵护方案',
                        img:'sjjk.jpg',
                        detail:'本方案适用于司机“健康+任务”管理领域。健康管理方面，通过本方案及其产品，可对司机实时监测体温、心率、血压、HRV等数据;任务管理方面，根据对司机驾车前规范及要求，通过智能器具可实现绕车巡视、上车打卡等规定性动作的监测管理。'
                    },{
                        name:'失能老人辅助监测评估方案',
                        img:'snlr.jpg',
                        detail:'心麦利用自身智能监测设备监测失能人员健康状况，利用云平台数据管理中心对收集到的数据进行整理和分析，最终提供监测报告作为无锡市医保局失能等级评估的科学依据。'
                    },
                    {
                        name:'中信VIP客户关怀方案',
                        img:'zxyh.jpg',
                        detail:'心麦为银行高级用户提供全方位一站式的健康管理服务：\n' +
                            '高级VIP客户可拨打银行电话获得365天✖24小时私人医生健康咨询；\n' +
                            '拥有强大的专家资源库，帮助高级VIP客户解决各种健康问题；\n' +
                            '通过可穿戴设备，传递身体状况至终端，私人医生会为您保驾护航；\n' +
                            '包含医疗查询、电子健康档案、在线疾病咨询、远程会诊治疗和康复等多种形式的管家服务让您享受更便捷的医疗过程。'
                    },{
                        name:'景区智能化管理方案',
                        img:'nhw.png',
                        detail:'与华为合作，将华为两款智能手表接入至心麦数据管理平台中，通过通知提醒、AI语音助手、商业活动推送、景区预约服务、全天运动及健康监测等多个场景应用，实现景区智能管理。'
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
            this.$parent.toPage(2)
        },
    }
</script>

<style scoped>
    .banner{
        /*height: 600px;*/
        overflow: hidden;
        position: relative;
    }
    .banner .mengban{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        background: rgba(0,0,0,0.2);
    }
    .mengban span{
        display: block;
        font-size: 99px;
        color: #fff;
        margin-top: 180px;
        border-bottom: 1px solid #fff;
        position: relative;
    }
    .mengban div{
        width: 1440px;
        margin: 0 auto;
    }
    .mengban span:nth-child(1){
        text-align: left;
    }
    .banner img{
        width: 100%;
    }
    .solution{
        width: 1440px;
        margin: 100px auto;
    }
    .solution h3{
        font-size: 34px;
        margin: 50px 0;
    }
    .solution li{
        width:100%;
        position: relative;
    }
    .solution li img{
        width: 100%;
    }
    .solution li > div{
        position: relative;
    }
    .solution li > div > span{
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: left;
        font-size: 24px;
        background: rgba(255,255,255,0.8);
        padding: 0 10px;
    }
    .solution li > span{
        width: 100%;
        height:100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: rgba(255,255,255,0.8);
        font-size: 24px;
        display: none;
        transition: 1s;
    }
    .solution li > span > span{
        display: block;
        width: 90%;
        margin: 0 auto;
        line-height:60px;
        text-align: left;
        padding-top: 70px;
    }
    .solution li:hover .hover-info{
        display: block;
        transition: 5s;
        animation: rect 0.3s ease;
        animation-fill-mode: forwards; /*保持最后一帧的状态*/
    }
    @keyframes rect {
        from {
            height: 0;
        }
        to {
            height: 100%;
        }
    }
</style>
