<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>热烈欢迎无锡医保中心各位领导莅临心麦指导工作</h2>
            <span>2021-05-19</span>
            <div class="wrap-news-content">
                <p>  2021年5月19日下午，无锡医保中心王海阳副局长带队和江南大学医学院蒋玉宇教授、无锡太湖学院护理学院周鸣鸣院长一行莅临心麦参观考察，心麦（江苏）公司董事长胡玉霞、总经理缪建洪以及运营经理尹俊峰给予了热情的招待。</p>
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <span>{{item.tips ? item.tips : ''}}</span>
                    <p>{{item.summary ? item.summary : ''}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail3",
        data () {
            return {
                newsList: [
                    {
                        img: '003.png',
                        tips: '',
                        summary: '领导们首先来到心麦视界，缪总就公司发展提出“1+N”战略（系列智能感知系统为1，多场景数字健康解决方案为N），介绍心麦各系列产品，缪总对各个落地场景（医保中心失能老人的复核场景、智慧城市公交管理场景、智慧健康校园管理、慢病监测体验区、适老化改造系统、睡眠管理系统）以及今后的发展规划做了相关介绍，尤其针对智慧失能老人复核设备以及医保中心失能老人的复核场景进行了详细介绍。'
                    },
                    {
                        img: '003_1.jpg',
                        tips: '智慧失能老人复核设备讲解',
                        summary: '领导们具体了解了心麦设备详情、设备落地场景、公司的发展过程以及今后的发展规划，领导为此指出了一些建议和意见，并希望公司在日后发展中能得到进一步的提升。'
                    },
                    {
                        img: '003_2.jpg',
                        tips: '慢病监控系列设备讲解',
                        summary: '公司总体参观后，心麦运营中心负责人尹俊峰介绍了具体医保中心失能老人的复核场景的项目实施方案；江南大学无锡医学院蒋教授对利用智慧失能老人复核设备收集到的健康数据结果进行了分析汇报，无锡市医保局王局、陈部长、张主任以无锡太湖学院护理学院周院长等提出了智能设备在应用场景落地的具体建议。'
                    },
                    {
                        img: '003_3.jpg',
                        tips: '共同观看心麦场景宣传片',
                        summary: '王局肯定了项目的价值。医保中心陈部长等指出，利用科技的手段辅助评估机构对失能老人进行等级评估方向是极为有利的，将有效大幅减少佯装丧失行为能力欺瞒复核人员的骗保行为。江南大学医学院蒋教授对设备临床采集数据进行系统分析判断，得出结论：心麦（江苏）自主研发的智慧医保设备对失能老人的复核评估具有有效的参考和监督价值。'+
                                '无锡医保中心希望与心麦合作进一步落实，同时期望心麦不断提升企业综合实力，提高在行业中的地位，为促进智能健康事业做出更大的贡献。'

                    },
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
