<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/zz.jpg" alt="">
        </div>
        <div class="wrap-news">
            <div class="wrap-news-content">
                <keep-alive>
                    <img v-if="index==1" src="../../assets/img/qualification1.png" alt="">
                    <img v-else-if="index==2" src="../../assets/img/qualification2.png" alt="">
                    <img v-else-if="index==3" src="../../assets/img/qualification3_ps.png" alt="">
                    <img v-else-if="index==4" src="../../assets/img/qualification4.png" alt="">
                    <img v-else-if="index==5" src="../../assets/img/qualification5.jpg" alt="">
                </keep-alive>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail1",
        data(){
            return{
                index:3
            }
        },
        mounted() {
            this.index = this.$route.query.index
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部

        },
    }
</script>

<style scoped>
    .banner img{
        width: 100%;
    }
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
   .wrap-news-content img{
       width: 100%;
   }
</style>
