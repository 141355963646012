<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>浓浓关怀暖人心，殷殷期许勇奋进</h2>
            <span>2021-09-06</span>
            <div class="wrap-news-content">
                <p>2021年9月6日，心麦为新婚归来的员工-产品部徐荣荣精心准备了庆祝仪式，小小的惊喜带给了新人暖暖的祝福，全体员工一起分享了新人的带来的甜蜜与喜悦，心麦以质朴而浓厚的爱意，期许和呵护着每一个成长中的年轻人，期待在心麦的大家庭中迅速的成长并肩负重任，勇往直前。 </p>
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <span>{{item.tips ? item.tips : ''}}</span>
                    <p>{{item.summary ? item.summary : ''}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail6",
        data () {
            return {
                newsList: [
                    {
                        img: '008_1.jpg',
                        tips: '',
                        summary: '活动开始前，所有员工都“严阵以待”，并准备好了花束和庆祝用的小礼炮，在公司门口列队欢迎，紧紧盯着门外的电梯，等待新郎官出场的一瞬。“叮——”的一声，包晓霞带着主人公——徐荣荣出现在了大家的视野中。'
                    },
                    {
                        img: '008_2.jpg',
                        tips: '',
                        summary: '“砰——砰——砰”的礼炮声后是大家对主人公的祝福，大家好奇地询问徐荣荣对结婚的感受，一句“结婚有点累”成功逗笑了大家，伴随着一句句真挚的话语，一声声发自内心的祝福，公司里起伏不绝的笑语欢声感染着在场的所有人。此次活动让大家都感受到了公司家一样的温情氛围，对于员工的浓浓关怀，这一切都让公司的大家更加亲近，也更加团结。'
                    },
                    {
                        img: '008_3.jpg',
                        tips: '',
                        summary: '活动过后，所有员工再次回到了自己的岗位上，适度的活动与愉悦过后，是大家对于工作更加积极热情，公司对每个人的关怀期许也让我们所有人以更饱满的精神态度直面前方、砥砺前行！'
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
