<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>紧扣时代脉搏，拓展数字经济</h2>
            <span>2021-09-26</span>
            <div class="wrap-news-content">
                <p>2021年9月24日，新华日报社樊万朝社长一行莅临心麦江苏，与董事长胡玉霞、总裁缪卫洪一起探讨数字经济时代下AI技术赋能健康产业的话题，并就如何发挥双方优势合作多赢达成共识。 </p>
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <span>{{item.tips ? item.tips : ''}}</span>
                    <p>{{item.summary ? item.summary : ''}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail6",
        data () {
            return {
                newsList: [
                    {
                        img: '009_1.jpg',
                        tips: '',
                        summary: '《新华日报》是中国共产党在抗日战争、解放战争初期的大型机关报，由周恩来亲自创办。2001年9月28日，经中宣部、国家新闻出版总署批准，江苏省委、省政府研究，以新华日报为主报的新华报业传媒集团正式组建。在信息科技迅速发展的当代，新华日报不断地寻求突破，与科技型企业合作，不断丰富新媒体内涵，增加新媒体对公众的服务性，达到更好的推广效应。'
                    },
                    {
                        img: '009_2.jpg',
                        tips: '',
                        summary: '心麦是致力于大数据、大健康的高新技术企业，基于软硬件平台建设打造数字化健康引擎，通过 IOT 技术对人体的健康数据进行采集、分析和运用，从而实现监测、预测、干预、治愈全过程数字化健康管理。公司在智慧养老、智慧校园、智慧交通等场景提供整体解决方案。如心麦与无锡市医保局展开合作，利用智能设备+数据算法实现对无锡市失能人员等级评估工作；与无锡南外国王国际学校合作开展学生体温监测预警、考勤管理、食堂支付、门锁系统等智慧管理工作；心麦数据健康服务在其他领域也有广泛应用，并将形成标杆项目在全国范围内进行复制。'
                    },
                    {
                        img: '009_3.jpg',
                        tips: '',
                        summary: '胡总、缪总向樊社长一行详细介绍了心麦发展历程及研发积累、近期取得的突破以及心麦未来的发展方向。樊社长对心麦的技术研发及发展方向予以充分认可，同时也介绍了新华日报后续在新媒体和数字经济产业园的布局。双方都期待能形成合力共同推进数字经济产业发展，利用新技术新手段将健康服务管理普及化、数据化、可视化、精细化。'
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
