<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>祝贺心麦与江西省康复辅具技术中心合作研讨会议圆满成功！</h2>
            <span>2021-11-24</span>
            <div class="wrap-news-content">
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <p>{{item.summary ? item.summary : ''}} </p>
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                </div>
                <p>此次会谈完美落下帷幕后,相信不久的将来,心麦与江西省康复辅具技术中心达成合作,可实现优势互补、强强联合，进一步促进江西省智慧健康养老产业发展，积极应对人口老龄化，打造信息技术产业发展新动能，满足人民群众日益迫切的健康及养老需求，增进人民福祉和促进经济社会可持续发展。</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail6",
        data () {
            return {
                newsList: [
                    {
                        summary: '2021年11月24日，心麦总裁缪卫洪和产品经理方俊受邀参加于江西省康复辅具技术中心举办的研讨会议，江西省康复辅具技术中心郭承茂书记和几位领导班子成员何威副主任、邓润生副主任、黄毅副主任、裘爱民副主任、王鹏副主任一起参加了本次会议，双方就利用智能设备结合数字化技术提升养老服务质量的初步规划达成共识，今后将在养老服务的多个领域开展深度合作,助力江西省养老事业信息化、智慧化发展。',
                        img: '010_1.png'
                    },
                    {
                        summary: '首先，江西省康复辅具技术中心领导介绍了就江西省民政厅在养老领域的发展规划和实际情况，针对此点，缪总介绍了心麦近期在数字健康领域，特别是养老场景的发展现状以及取得的成效和未来的构思。为了更好的展现我们在该领域的具体应用情况，产品经理方俊介绍了我们利用智能器具进行失能等级评估的方案，并利用我们成套产品在现场进行了实际操作演示。',
                        img: '010_2.png'
                    },
                    {
                        summary: '通过现场演示，江西省康复辅具技术中心领导高度认可心麦在软件平台建设、基础网络建设、智能硬件设备建设等方面实现智能化、标准化、数字化，协助推进科技助老的方式。江西省康复辅具技术中心表示高度认可我们的产品和模式,双方将在民政领域社区居家养老服务项目、失能照护项目等开展深入合作。在江西省康复辅具技术中心领导的带领下,心麦参观了康复中心的新基地。',
                        img: '010_3.png'
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
