<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>祝贺心麦与常州市医保局合作研讨会议圆满成功</h2>
            <span>2023-05-15</span>
            <div class="wrap-news-content">
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <!-- <span>{{item.tips ? item.tips : ''}}</span>
                        <p>{{item.summary ? item.summary : ''}}
                        </p> -->
                </div>
                <p>2023年5月15日，失能评估项目合作讨论会议在常州市医保局会议室召开，心麦公司领导和常州市医保局领导均到场参加了此次会议。会议中双方就项目运营模式、实施情况、疑点难点等问题展开了深入探讨并充分交换想法意见，为双方后续的合作打下了良好基础。</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail16",
        data () {
            return {
                newsList: [
                    {
                        img: '016.jpg',
                    },
                    {
                        img: '016_2.jpg',
                    },
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
