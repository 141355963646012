<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>热烈欢迎无锡医保中心各位领导莅临心麦指导工作</h2>
            <span>2021-04-06</span>
            <div class="wrap-news-content">
                <p>4月5日上午，南外国王国际学校尤小艳校长，中信银行无锡分行私行部副总经理岳科杰、营业部总经理顾培、营业部副总经理李文军一行莅临心麦（江苏）进行考察，由心麦董事长胡玉霞、董事总裁缪建洪、技研中心总监鲍健明、产品中心总监杨帆陪同接待。</p>
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <span>{{item.tips ? item.tips : ''}}</span>
                    <p>{{item.summary ? item.summary : ''}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail2",
        data () {
            return {
                newsList: [
                    {
                        img: '002.jpg',
                        tips: '',
                        summary: '领导们首先来到心麦视界，缪总就公司发展提出“1+N”战略（系列智能感知系统为1，多场景数字健康解决方案为N），介绍心麦各系列产品，缪总对各个落地场景（智慧城市公交管理场景、智慧健康校园管理、慢病监测体验区、适老化改造系统、睡眠管理系统）以及今后的发展规划做了相关介绍，尤其针对心麦智能设备在智慧健康校园场景的应用。'
                    },
                    {
                        img: '002_1.jpg',
                        tips: '',
                        summary: '会议中，两方领导就此次考察初步研讨出关于南外国王国际学校应用场景的落地方案，对公司项目的产品计划、质量控制、时间节点等方面进行了详细的了解，交换了意见，并对下一步两方战略合作进行了深入探讨。近日双方科技人员将进行现场技术考察，推动落实下一步工作。'
                    },
                    {
                        img: '002_2.jpg',
                        tips: '',
                        summary: '南外国王国际学校与心麦公司期待有进一步的合作，共同推动智慧健康校园项目在南外国王国际学校的落成。'
                    },
                    {
                        img: '002_3.jpg',
                        tips: '',
                        summary: ''
                    },
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
