<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>心麦员工庆祝建党100周年</h2>
            <span>2021-07-02</span>
            <div class="wrap-news-content">
                <p>2021年7月1日是中国共产党成立100周年日子，全国各地都在以自己的方式庆祝党的100岁生日。在这特别的日子里，心麦全体员工也组织了一次小小的庆典活动，祝福我们伟大的共产党100岁生日快乐！</p>
<!--                <div>-->
<!--                    <img src="~@/assets/img/news/005.jpg" alt="">-->
<!--                </div>-->
<!--                <span>庆祝党建，心麦准备的100岁生日蛋糕</span>-->
<!--                <div>-->
<!--                    <img src="~@/assets/img/news/005_1.jpg" alt="">-->
<!--                </div>-->
<!--                <span>插上蜡烛，仪式感满满！</span>-->
<!--                <div>-->
<!--                    <img src="~@/assets/img/news/005_2.jpg" alt="">-->
<!--                </div>-->
<!--                <p>在刚刚过去的雪浪大会中总裁缪卫洪曾讲到：心麦将把握机遇，将数字健康领域进一步拓宽，为人民美好生活贡献力量；借助这神圣的日子，董事长胡玉霞也教导所有员工说，心麦要做利国利民的事业！-->
<!--                    心麦员工将心怀感恩，感恩在共产党的领导下祖国日新月异的变化，同时也将不辱使命，做一个探路者，做难而正确的事情，坚持自己的梦想，以自己方式回报党，回报祖国和人民！-->
<!--                </p>-->
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <span>{{item.tips ? item.tips : ''}}</span>
                    <p>{{item.summary ? item.summary : ''}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail5",
        data () {
            return {
                newsList: [
                    {
                        img: '005.jpg',
                        tips: '庆祝党建，心麦准备的100岁生日蛋糕',
                        summary: ''
                    },
                    {
                        img: '005_1.jpg',
                        tips: '插上蜡烛，仪式感满满！',
                        summary: ''
                    },
                    {
                        img: '005_2.jpg',
                        tips: '',
                        summary: '在刚刚过去的雪浪大会中总裁缪卫洪曾讲到：心麦将把握机遇，将数字健康领域进一步拓宽，为人民美好生活贡献力量；借助这神圣的日子，董事长胡玉霞也教导所有员工说，心麦要做利国利民的事业！' +
                            '心麦员工将心怀感恩，感恩在共产党的领导下祖国日新月异的变化，同时也将不辱使命，做一个探路者，做难而正确的事情，坚持自己的梦想，以自己方式回报党，回报祖国和人民！'
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
