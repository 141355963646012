import Vue from 'vue'
import Router from 'vue-router'
import routerView from "../views/common/routerView"
import home from '../views/common/home'
import about from '../views/about/index'
import solution from '../views/solution/index'
import product from '../views/product/index'
import presence from '../views/presence/index'
import qualification from '../views/qualification/index'
import qone from '../views/qualification/q-one'
import qtwo from '../views/qualification/q-two'
import qthree from '../views/qualification/q-three'
import qfour from '../views/qualification/q-four'
import news from '../views/news/index'
import detail1 from "../views/news/detail1"
import detail2 from "../views/news/detail2"
import detail3 from '../views/news/detail3'
import detail4 from '../views/news/detail4'
import detail5 from "../views/news/detail5"
import detail6 from "../views/news/detail6"
import detail7 from "../views/news/detail7"
import detail8 from "../views/news/detail8"
import detail9 from "../views/news/detail9"
import detail10 from "../views/news/detail10"
import detail11 from "../views/news/detail11"
import detail12 from "../views/news/detail12"
import detail13 from "../views/news/detail13"
import detail14 from "../views/news/detail14"
import detail15 from "../views/news/detail15"
import detail16 from "../views/news/detail16"
import detail17 from "../views/news/detail17"
import detail18 from "../views/news/detail18"
import detail19 from "../views/news/detail19"
import frame from '../views/common/homepage'

Vue.use(Router)
export default new Router({
    routes: [{
        path: '/',
        name: '',
        component: frame,
        children: [{
                path: '',
                name: '',
                component: home,
                meta: { title: 'HOME' },
            },
            {
                path: 'solution',
                name: 'solution',
                component: solution,
                meta: { title: '解决方案' }
            },
            {
                path: 'product',
                name: 'product',
                component: product,
                meta: { title: '智能设备' }
            },
            {
                path: 'news',
                name: 'news',
                component: routerView,
                meta: { title: '新闻资讯' },
                children:[
                    {
                        path:'list',
                        name:'list',
                        component:news,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail1',
                        name:'detail1',
                        component:detail1,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail2',
                        name:'detail2',
                        component:detail2,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail3',
                        name:'detail3',
                        component:detail3,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail4',
                        name:'detail4',
                        component:detail4,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail5',
                        name:'detail5',
                        component:detail5,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail6',
                        name:'detail6',
                        component:detail6,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail7',
                        name:'detail7',
                        component:detail7,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail8',
                        name:'detail8',
                        component:detail8,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail9',
                        name:'detail9',
                        component:detail9,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail10',
                        name:'detail10',
                        component:detail10,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail11',
                        name:'detail11',
                        component:detail11,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail12',
                        name:'detail12',
                        component:detail12,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail13',
                        name:'detail13',
                        component:detail13,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail14',
                        name:'detail14',
                        component:detail14,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail15',
                        name:'detail15',
                        component:detail15,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail16',
                        name:'detail16',
                        component:detail16,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail17',
                        name:'detail17',
                        component:detail17,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail18',
                        name:'detail18',
                        component:detail18,
                        meta:{title:'LIST',icon:'document'},
                    },
                    {
                        path:'detail19',
                        name:'detail19',
                        component:detail19,
                        meta:{title:'LIST',icon:'document'},
                    },
                ]
            },
            {
                path: 'presence',
                name: 'presence',
                component: presence,
                meta: { title: '员工风采' }
            },
            {
                path: 'about',
                name: 'about',
                component: about,
                meta: { title: '关于我们' }
            },
            {
                path: 'qualification',
                name: 'qualification',
                component: qualification,
                meta: { title: '经营资质' }
            },
            {
                path: 'qone',
                name: 'qone',
                component: qone,
                meta: { title: '经营资质' }
            },
            {
                path: 'qtwo',
                name: 'qtwo',
                component: qtwo,
                meta: { title: '经营资质' }
            },
            {
                path: 'qthree',
                name: 'qthree',
                component: qthree,
                meta: { title: '经营资质' }
            },
            {
                path: 'qfour',
                name: 'qfour',
                component: qfour,
                meta: { title: '营业执照' }
            },
        ]
    }]
})
