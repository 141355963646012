<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>喜报！心麦获得智慧医保解决方案大赛优胜奖</h2>
            <span>2022-09-24</span>
            <div class="wrap-news-content">
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <!-- <span>{{item.tips ? item.tips : ''}}</span>
                        <p>{{item.summary ? item.summary : ''}}
                        </p> -->
                </div>
                <p>2022年9月24日，由国家医疗保障局主办的“智慧医保解决方案大赛”颁奖典礼在京圆满落幕。本次大赛分为：精准医保决策、精准医保监管、精准医保服务三个赛道，收到了来自全国25个省市区的133家企业提交的参赛方案，最终仅有12个方案获得优胜奖。经过初赛和复赛，心麦凭借《一种提升失能等级评估质量的解决方案》成功斩获大赛优胜奖。本次大赛获奖极大提升了团队的信心，未来团队将为中国智慧医保发展提供更多助力。</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail13",
        data () {
            return {
                newsList: [
                    {
                        img: '013.jpg',
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
