<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>心麦&华为携手并肩，砥砺前行，谱写新篇！</h2>
            <span>2021-12-09</span>
            <div class="wrap-news-content">
                <p>12月9日，心麦受邀参加了华为在无锡皇冠假日酒店隆重举行的“江苏终端政企合作伙伴大会”。会上，华为公司就合作伙伴的管理方面和产品进展以及目前的渠道政策向代理商做了详尽的介绍，获得了与会者的一致好评。 </p>
                <div class="wrap-news-list">
                    <div>
                        <img src="../../assets/img/news/011.jpg" alt="">
                    </div>
                    <span>心麦总裁缪卫洪与华为江苏终端政企部部长丁振宇</span>
                    <p>心麦作为华为的同路人,将携手并肩砥砺前行谱写新篇。心麦与各渠道商共同探讨了技术的发展、产品的应用,帮助合作伙伴在了解产业前沿技术发展的基础上，快速发掘和抓住新的商机，进而助力政企客户更好的应对数字化的挑战。
                        在会议上,华为终端消费者BG苏晓丽专门向与会的各位伙伴们推荐心麦,评价心麦是国内结合华为的穿戴类设备提供整体解决方案非常成功的一家企业，也表示十分重视双方的合作，期待未来有更好的成果。
                    </p>
                    <p>心麦始于2014年，是一家将人工智能、物联网、大数据、云计算等技术运用于大健康领域的高新技术企业，致力打造数字健康引擎，通过对人体的生理数据进行采集、分析和运用，辅以各种健康管理手段，从而实现监测、预测、干预、治愈全流程数字化闭环管理。公司在智慧养老、校园健康、特定人群管理等场景重点发力，提供整体解决方案。</p>
                    <div>
                        <img src="../../assets/img/news/011_1.jpg" alt="">
                    </div>
                    <span>心麦智慧养老数字看板</span>
                    <div>
                        <img src="../../assets/img/news/011_2.jpg" alt="">
                    </div>
                    <span>心麦智能健康监测设备</span>
                    <p>始于用户需求，终于用户满意。心麦除了使用华为的智能硬件设备，通过自己这么多年的沉淀，还研发出了便携式一体机、睡眠带、跌倒报警器和定位报警器等智能健康监测设备,配合手机APP/小程序上的定制化健康服务，实现物联网+大数据+AI智能分析对用户身体健康，为用户提供全方面的健康守护服务。</p>
                    <div>
                        <img src="../../assets/img/news/011_3.jpg" alt="">
                    </div>
                    <span>企业客户了解心麦产品</span>
                    <div>
                        <img src="../../assets/img/news/011_4.jpg" alt="">
                    </div>
                    <span>企业客户参观心麦展厅企业</span>
                    <div>
                        <img src="../../assets/img/news/011_5.jpg" alt="">
                    </div>
                    <span>企业客户详细了解心麦智能健康应用场景解决方案</span>
                    <p>未来创新不止，产品服务同行。通过此次发布会这样一个机会，心麦看到了一个开放、合作的前景，也看到了无比明亮的未来，为心麦进一步的成长指明了方向。我们相信心麦会攻克重重困难，不断深入智能穿戴的运用领域！用行动产生服务能量，用行动赋予服务灵魂,在不断夯实硬件+算法+健康管理服务能力的同时，力求为广大用户带来更优质的服务体验。</p>
                    <p>在此祝贺会议圆满结束，心麦与华为合作更加深入！心麦光辉的未来，我们将拭目以待！</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
