<template>
    <div>
        <div class="header">
            <div class="header_content">
                <div class="logo">
                    <img src="~@/assets/img/icon_logo_new.png" alt="">
                </div>
                <div class="shortcut">
                    <ul>
                        <li @click="toPage(1)" :class="selected == 1 ? 'cur' : ''"><router-link :to="{path: '/'}">首页</router-link></li>
                        <li @click="toPage(2)" :class="selected == 2 ? 'select_info cur' : 'select_info'"><a href="#">1+N</a><span class="select_icon"></span>
                        <div class="select_detail">
                            <div class="equipment"><router-link :to="{path: '/product'}">智能设备</router-link></div>
                            <div class="equipment"><router-link :to="{path: '/solution'}">解决方案</router-link></div>
                        </div>
                        </li>
                        <!-- <li>
                            <div class="select_detail">
                                <div><a href="#">智能设备</a></div>
                                <div><a href="#">解决方案</a></div>
                            </div>
                        </li> -->

                        <li @click="toPage(3)" :class="selected == 3 ? 'cur' : ''"><router-link :to="{path: '/news/list'}">新闻资讯</router-link></li>
                        <li @click="toPage(4)" :class="selected == 4 ? 'cur' : ''"><router-link :to="{path:'/presence'}">员工风采</router-link></li>
                        <li @click="toPage(5)" :class="selected == 5 ? 'cur' : ''"><router-link :to="{path: '/about'}">关于我们</router-link></li>
                        <div style="clear:both"></div>
                    </ul>
                </div>
                <div style="clear:both"></div>
            </div>

        </div>
        <div><router-view></router-view></div>
        <div class="footer">
            <div class="mod_content">
                <div class="con_del">
                    <img src="~@/assets/img/icon_logo_about_new.png" alt="" class="con_del_logo" />
                    <div class="con_del_help">
                        <ul>
                            <li class="del_help_tit"><router-link :to="{path: '/about'}">关于我们</router-link></li>
                            <li><router-link :to="{path: '/about'}">公司简介</router-link></li>
                            <li><router-link :to="{path: '/news/list'}">新闻资讯</router-link></li>
                            <li><router-link :to="{path:'/presence'}">员工风采</router-link></li>
                        </ul>
                        <ul>
                            <li class="del_help_tit">1+N</li>
                            <li><router-link :to="{path: '/product'}">智能设备</router-link></li>
                            <li><router-link :to="{path: '/solution'}">解决方案</router-link></li>
                        </ul>
                    </div>
                    <div class="con_del_address">
                        <p class="firm_name">无锡心动麦力科技有限公司</p>
                        <p style="overflow:hidden">
                            <span class="company-addr-tip">公司地址：</span>
                            <span class="company-addr">
                                <span>江苏省无锡市滨湖区金融三街6号楼太湖新城 置业大厦21楼2108-2114号</span>
                                <span>江苏省江阴市滨江西路8号国家软件园16楼</span>
                            </span>
                        </p>
                        <p>客服热线：400-088-2521</p>
                    </div>
                </div>
<!--                <div class="content_scan">-->
<!--                    <div>-->
<!--                        <img src="~@/assets/img/app.jpg" alt="" />-->
<!--                        <p>下载麦力健康APP（苹果）</p>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <img src="~@/assets/img/apk.jpg" alt="" />-->
<!--                        <p>下载麦力健康APP（安卓）</p>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <img src="~@/assets/img/code_demo.png" alt="" />-->
<!--                        <p>微信订阅号</p>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <img src="~@/assets/img/code_demo.png" alt="" />-->
<!--                        <p>微信服务号</p>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <div class="mod_copyright">
                <div class="mod_copyright_con">
                    <div class="copyright_con_num">
                        <span class="copyright">麦力健康智能科技 XinMob ©2014-2022<i style="display:inline-block;margin:0 10px"></i><a
                                href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022005788号</a></span>
                        <router-link :to="{name: 'qualification', query: {index: 1 }}"> 广播电视节目制作经营许可证</router-link>
                        <router-link :to="{name: 'qone', query: {index: 2 }}"> 互联网药品信息服务资格证书</router-link>
                        <router-link :to="{name: 'qtwo', query: {index: 3 }}"> 增值电信业务经营许可证</router-link>
                        <router-link :to="{name: 'qthree', query: {index: 4 }}"> 出版物经营许可证</router-link>
                        <router-link :to="{name: 'qfour', query: {index: 5 }}"> 营业执照</router-link>
                        <a href="#">隐私政策</a>
                        <span>|</span>
                        <a href="#">免责声明</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "homepage",
        
        data() {
            return {
                selected: 1,
            }
        },
        methods:{
            toPage(val) {
                // console.log(val)
                this.selected = val
            },
        },
    }
</script>

<style scoped>
    .header{
        width: 100%;
    }
    .header_content{
        width: 1440px;
        margin: 0 auto;
        /* overflow: hidden; */
    }
    .header ul{
        position: relative;
        /* overflow: hidden; */
    }
    .header .logo{
        float: left;
        width: 20%;
        text-align: left;
        padding: 14px 0;
    }
    .header .logo img {
        width: 48%;
    }
    .header .shortcut{
        float: left;
        width: 60%;
    }
    .header ul li{
        list-style: none;
        float: left;
        height: 28px;
        padding: 20px 50px;
        display: block;
        position: relative;
    }
    .header ul li a,
    .header ul div a{
        color: #333!important;
        font-size: 14px;
    }
    .header ul .cur a{
        color: #FF6A00;
    }
    .header ul .cur,
    .header ul > li:hover{
        border-bottom: 3px solid #FF6A00;
    }
    .header ul > li:hover a,
    .header ul div:hover a{
        color:#FF6A00;
    }
    .select_icon{
        width: 5px;
        height: 5px;
        border-right: 1px solid #333;
        border-bottom: 1px solid #333;
        position: absolute;
        top:35%;
        display: block;
        right: 25%;
        transform:rotate(45deg);
    }
    .header ul > li:hover .select_icon{
        border-right: 1px solid #FF6A00;
        border-bottom: 1px solid #FF6A00;
    }
    .select_detail{
        display: none;
        position: absolute;
        top: 70px;
        left: 0px;
        width: 125px;
        height: 90px;
        background-color: #fff;
        font-size: 14px;
        z-index: 999;
    }
    .select_info:hover .select_detail{
        display: block!important;
    }
    .select_detail .equipment,
    .select_detail .project{
        width: 100%;
        height: 38%;
        margin: 11px 0;
    }
    .select_detail .equipment{
        border-bottom: 1px solid #ccc;
    }
     .header ul .select_detail .equipment:hover a,
     .header ul .select_detail .project:hover a{
         color:#FF6A00!important;
     }
    .footer{
        width: 100%;
    }
    .mod_content{
        width: 100%;
        height: 450px;
        background-color: #333;
    }
    .con_del{
        width: 100%;
        height: 50%;
        overflow: hidden;
    }
    .con_del_logo,
    .con_del_help ul,
    .con_del_address{
        float: left;
        margin-top: 4%;
    }
    .con_del_address p{
        text-align: left;
    }
    .con_del_logo{
        width: 7%;
        margin-left: 15%;
    }
     .con_del_help ul{
         width: 5.5%;
         margin-left: 5%;
     }
     .con_del_help ul li{
         padding: 0 8px 16px;
         color: #FFF;
         font-size: 14px;
     }
     .con_del_help ul li a{
         color: #FFF;
         font-size: 14px;
     }
     .con_del_help ul .del_help_tit{
         color: rgba(255, 255, 255, .5);
         font-size: 14px;
     }
     .del_help_tit a{
         color: rgba(255, 255, 255, .5) !important;
         font-size: 14px;
     }
     .con_del_address .firm_name{
         color: rgba(255, 255, 255, .5);
         font-size: 14px;
         margin-bottom: 15px;
     }
     .con_del_address{
         width: 40%;
         margin-left: 10%;
     }
     .con_del_address p{
         color: #FFF;
         font-size: 14px;
     }
    .mod_copyright{
        width: 100%;
        height: 55px;
        background-color: #1A1A1A;
    }
    .content_scan{
        width: 620px;
        margin: 45px auto;
        overflow: hidden;
        display: flex;
    }
    .content_scan div{
        width: 25%;
    }
    .content_scan p{
        padding: 18px 0;
        color: #FFF;
        font-size: 12px;
    }
    .mod_copyright{
        width: 100%;
        overflow: hidden;
    }
    .mod_copyright_con{
        width: 80%;
        height: 40px;
        line-height: 40px;
        margin: 5px auto;
        text-align: center;
    }
    .copyright_con_num{
        color: #FFF;
        font-size: 12px;
    }
    .copyright_con_num a:hover{
        color: #FF6A00;
    }
    .company-addr-tip,.company-addr{
        float: left;
    }
    .company-addr-tip{
        width: 70px;
    }
    .company-addr{
        width: 80%;
    }
    .company-addr span{
        display: block;
        margin-bottom: 18px;
        text-align: left;
    }
    .copyright_con_num a{
        color: #FFF;
        font-size: 12px;
        margin-left: 10px;
    }
    .copyright_con_num span{
        margin-left: 10px;
    }
    .copyright_con_num .copyright{
        margin-right: 70px;
    }
</style>
