<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj1.png" alt="">
        </div>
        <div class="smart-device">
            <div class="smart-title">
                <div class="smart-f-title">
                    <span>智能</span><span style="color: #ff4100">硬件</span>
                </div>
                <span style="text-transform: uppercase">Intelligent hardware</span>
            </div>
            <div class="smart-device-content">
                <ul>
                    <li v-for="(item,index) in device" :key="index">
                        <div class="wrap-device-detail">
                            <div class="device-title">
                                <img :src="require('../../assets/img/'+item.img)" alt="">
                                <span>{{item.name}}</span>
                            </div>
                            <div class="device-detail">
                                <h3>{{item.name}}</h3>
                                <p>{{item.detail}}</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                device:[
                    {
                        img:'device_warm.png',
                        name:'暖心手环Pro',
                        detail:'男女同款多功能蓝牙智能手环,实时监测体温心率睡眠和压力值并生成健康档案。'
                    },
                    {
                        img:'pic_anxb@2x.png',
                        name:'安心手表',
                        detail:'安心手表是一款关于健康的智能穿戴电子产品，能够对身体健康进行实时监测，它自带定位功能，监测到您的所在位置，当需要救助时，一键SOS功能发出求救信息，随时保证您的人生安全。'
                    },
                    {
                        img:'device_gift.png',
                        name:'健康守护礼盒',
                        detail:'本公司出品的健康守护礼包，含有智能手表、一体机、睡眠带、跌倒报警器和定位报警器5件智能健康监测设备,\n' +
                            '配合手机APP/小程序上的定制化健康服务，实现物联网+大数据+AI智能分析对用户身体健康的监测、预测、干预、治愈的全流程管理，\n' +
                            '为用户提供全方面的健康守护服务。'
                    },
                    {
                        img:'device_fall.png',
                        name:'跌倒报警器',
                        detail:'24小时监测老人身体情况,一旦老人跌倒会及时发出警报,监控人员可查询老人定位进行救助。'
                    },
                    {
                        img:'pic_nxytj@2x.png',
                        name:'省心一体机',
                        detail:'省心一体机是一款适合慢病监测的智能健康电子设备，能够对试验者的血压、血糖、心率、尿酸、胆固醇、甘油三酯等数据同时监测。满足慢性病群体的多项指标管理需求。'
                    },
                    {
                        img:'device_location.png',
                        name:'定位报警器',
                        detail:'设定安全围栏,确定老人的安全活动范围,离开安全范围,可发出警报通知并对老人进行定位。'
                    },
                ],
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
            this.$parent.toPage(2)
        },
        methods: {
        },
    };
</script>

<style scoped>
    .banner{
        width: 100%;
    }
    .banner img{
        width: 100%;
    }
    .smart-server{
        width: 100%;
        background: #F4F5F7;
        padding-bottom: 90px;
    }
    .smart-title,.smart-content{
        width: 1440px;
        margin: 0 auto;
    }
    .smart-content ul{
        display: flex;
        flex-flow:row-reverse wrap;
    }
    .smart-content ul li{
        color: #333;
        width:25%;
    }
    .smart-content ul li > div{
        background: #fff;
        transition: 0.5s;
        color: #333;
        margin: 10px;
        border-radius: 6px;
        padding: 50px 0;
    }
    .smart-c-title span:nth-child(2){
        font-size: 12px;
        letter-spacing: 0;
    }
    .smart-content ul li:hover > div{
        background: #ff4100;
        transition: 0.7s;
        color: #fff!important;
        border-radius: 6px;
    }
    .smart-content ul li > div > div span{
        display: block;
        margin-top: 10px;
    }
    .smart-title{
        padding: 40px 0;
    }
    .smart-title > span{
        font-size: 14px;
        color: #7F7F7F;
        letter-spacing: 0;
        display: block;
        margin-top: 10px;
    }
    .smart-f-title{
        font-size: 30px;
    }
    .index-about img{
        width: 100%;
    }
    .smart-device{
        padding: 60px 0;
    }
    .smart-device-content{
        width: 1440px;
        margin: 0px auto;
    }
    .smart-device-content ul{
        display: flex;
        flex-flow:row-reverse wrap;
    }
    .smart-device-content ul li{
        width: 33.33%;
        margin-top: 30px;
    }
    .smart-device-content ul li>div{
        background: #FFFFFF;
        border: 1px solid #ECECEC;
        margin: 20px;
        height: 400px;
        position: relative;
    }
    .device-title{
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
    .device-title img{
        width: 80%;
    }
    .device-title span{
        font-size: 24px;
        color: #333333;
        letter-spacing: 0.46px;
        display: block;
        text-align: center;
    }
    .device-detail{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background:rgba(255,255,255,0.98);
        height: 100%;
        padding: 30px;
        overflow: hidden;
        display: none;
        transition: 1s;
    }
    .device-detail h3{
        margin: 10px 0;
        transition: 1s;
    }
    .device-detail p{
        font-size: 16px;
        color: #666666;
        letter-spacing: 0.3px;
        line-height: 32px;
        margin-top: 60px;
        transition: 1s;
    }
    .smart-device-content ul li:hover .device-detail{
        display: block;
        animation: rect 0.5s ease;
        animation-fill-mode: forwards; /*保持最后一帧的状态*/

    }
    @keyframes rect {
        from {
            transform: scale(0.5, 0.5);
            box-shadow: 1px 1px 10px rgba(0,0,0,0.10);
            top: -30px;
        }
        to {
            transform: scale(1.1, 0.99);
            box-shadow: 1px 1px 10px rgba(0,0,0,0.10);
            top: -30px;
        }
    }
</style>
