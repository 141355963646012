<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>心麦2021上半年工作总结大会圆满结束</h2>
            <span>2021-07-13</span>
            <div class="wrap-news-content">
                <p>7月9日14:00，心麦组织召开了2021年上半年工作总结大会。会议中，综管中心首先就制定出来的适用于公司目前发展的最新版员工手册进行了宣讲和解读，以确保公司在业务高速发展期间能够保持规范而高效的运转。
                    随后心麦各部门负责人对半年以来的工作进行了总结汇报，同时就目前存在的问题进行了沟通交流。会议中大家同心同志，积极向上，对于影响团队发展的不良现场及存在的问题不约而同的持有坚定的态度，是本次会议的价值所在，也是心麦提倡的企业文化的表现。
                </p>
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <span>{{item.tips ? item.tips : ''}}</span>
                    <p>{{item.summary ? item.summary : ''}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail6",
        data () {
            return {
                newsList: [
                    {
                        img: '006_1.png',
                        tips: '人事部门讲解人事制度',
                        summary: '综管中心从公司规章制度概述、人事管理制度、财务管理制度等三方面进行了宣讲和解读，此次制度的更新核心把握了“价值引导，奖惩分明”的原则，员工制度管理的规范与否直接影响公司的效率，本次制度的更新也在于适应公司目前的发展现状，将有力保证公司更加高效的运转。'
                    },
                    {
                        img: '006_2.png',
                        tips: '技研中心工作汇报',
                        summary: '技研中心总监鲍健明对部门半年来的工作进行了概述。从“法加2.28迭代”到“南外国王项目”和“失能人员等级评估项目”，再到最近结束的“雪浪大会”，心麦技术团队以公司项目为导向，承接了一个个技术任务，攻克了一项项技术难题，虽然是短短的半年，与2020年相比，我们的方向更加明确，思路更加清晰，成长成为一个有承载能力和攻克实力的团队。'
                    },
                    {
                        img: '006_3.png',
                        tips: '产品中心工作汇报',
                        summary: '产品中心总监杨帆汇报了部门半年的工作。产品中心以公司项目为导向，以客户需求为目标，是实现产品功能和项目目标的关键环节。在公司业务高速发展的时期，这支平均年龄只有25岁的年轻团队客服了各种压力，高强度密集型的工作仍然保持着良好的状态，积极向上的工作态度得到了公司领导的充分肯定。'
                    },
                    {
                        img: '006_4.png',
                        tips: '运营中心工作汇报',
                        summary: '运营中心负责人王宁介绍了部门半年的工作。虽然上任不久，但王宁对部门工作的部署有条不紊，严谨细致，得到了缪总的肯定。运营部门是传达客户需求承上启下的重要环节，运营部门的人员配置是保证部门正常运作的关键，接下来的任务部署中将放入重点工作环节。'
                    },
                    {
                        img: '006.png',
                        tips: '',
                        summary: '最后，心麦员工就业务开展中存在的问题进行了交流互动，大家对问题的分析和解决都持有积极态度，也反映出了公司员工共同的价值取向。缪总对会议做了总结，深刻的讲解了公司文化和所崇尚的价值，激励着在场的每一名员工，此次会议圆满结束。'
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
