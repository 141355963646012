<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>心麦携手茁华和华为，打造智慧养老新高度</h2>
            <span>2021-12-17</span>
            <div class="wrap-news-content">
                <p>2021年12月17日,江苏茁华投资有限公司徐杰总经理和严于兰院长一行莅临我司参观考察,公司董事长胡玉霞、总裁缪卫洪热情接待，并邀请华为同事一起进行交流。</p>
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <span>{{item.tips ? item.tips : ''}}</span>
                    <p>{{item.summary ? item.summary : ''}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail6",
        data () {
            return {
                newsList: [
                    {
                        img: '012_1.jpg',
                        tips: '与会人员合影（部分）',
                        summary: '心麦缪总就公司发展和已取得的成果进行了介绍。“一种提升失能等级评估质量的解决方案”已顺利进入复赛并取得优胜奖这一殊荣;全国120救援系统已投入使用,可为心麦会员提供全国范围内的120救援，以及在养老领域做的其他工作和思考。\n'+
                                '随后,华为同事介绍了华为的以全屋智能方案：例如灯随影动，语音交互设备等；并讨论了因老年人听力障碍而无法及时开门的实际困难和对应的解决方案。 '
                    },
                    {
                        img: '012_2.jpg',
                        tips: '与会代表热烈讨论',
                        summary: '最后,茁华的徐总和严院长也分别表达了对目前的这个养老公寓项目的规划和愿景。表示为提高活力老人养老品质问题，愿与心麦、华为一起携手打造符合国家政策、人民需要的全生命周期的社区养老、居家养老新模式。\n'+
                                '会谈最后,三方表示，愿意发挥各自优势,实现强强联手,为无锡养老服务做更大贡献，引领养老产业健康发展!'
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
