<template>
    <div>
        <div class="banner">
<!--            <img src="~@/assets/img/pho_zhutu.png" alt="">-->
            <video id="v1" muted controls="controls" autoplay loop>
                <source src="https://xinmob.obs.cn-east-3.myhuaweicloud.com/company/daping.mp4" type="video/mp4">
                your browser does not support the video tag
            </video>
            <div class="mengban">
                <div>
                    <span>AI赋能大健康</span>
                    <span>DT夯实新基建</span>
                </div>
            </div>
        </div>
        <div class="about">
            <p>
                <span class="title">企业简介</span>
                <span>
                    无锡心动麦力科技有限公司是一家致力于大数据、大健康的科技型中小企业。
       心动麦力基于硬件软件平台的建设，极力打造数字化健康引擎，通过IoT+大数据对健康数据的研究，推动健康研究和行业的变革，特别是直接面向消费者的数字健康市场的发展。
       公司针对康养机构提供智慧养老、针对政府提供防疫监测、针对学校提供健康校园等多场景整体解决方案。
                </span>
                </p>
            <p>
                <span class="title">企业文化</span>
                <span>企业使命：让每个人都拥有自己的健康管家</span>
                <span> 企业愿景：健康创造财富</span>
                <span>企业定位：数字化健康整体解决方案服务商</span>
                <span>企业价值观：便捷、有效地为用户健康提供服务</span>
            </p>
            <p>
                <span class="title">发展历程</span>
                <span>2014年3月，上海心麦智能科技有限公司成立，核心团队由来自百度、三星，HP、复旦交大等专家组成</span>
                <span>2014年3月1日，上海心麦获得天使轮融资，投资方九合创投</span>
                <span>2015年11月，上海心麦“acare健康智能戒指全球首发“无创新不超越”新品发布会在北京国家会议中心举行</span>
                <span>2016年2月，上海心麦股权变更，新增股东——江阴市金渠资本管理有限公司</span>
                <span>2016年10月，上海心麦开心淘宝线上众筹开始，并最终众筹金额为41万元，得到了1400多位消费者的认可</span>
                <span>2016年，上海心麦获得上海市高新技术企业认证2017年，心麦开心手环上市</span>
                <span>2019年，上海心麦在上海举行上市签约仪式2019年，第二次获得上海高新技术企业认证</span>
                <span>2020年1月，上海心麦二代智能戒指参加了CES2020大展，其芯片获得了CES2020创新奖</span>
                <span>2020年2月，上海心麦推出暖心手环2020年5月，与远望神州合资成立心麦（江苏）大数据科技有限公司</span>
                <span>2020年8月，心麦（江苏）与快享（上海）合资成立无锡快享医疗科技有限公司</span>
                <span>2020年9月，心麦（江苏）与无锡太湖学院成立实习生基地</span>
                <span>2020年10月，心麦（江苏）自主开发的麦加APP正式上线</span>
                <span>2021年11月，无锡心动麦力科技有限公司成立</span>
            </p>
            <p>
                <span class="title">科室牌含义</span>
<!--                <span>西柏坡：敢于斗争、敢于胜利的开拓进取精神，依靠群众和团结统一的民主精神，戒骄戒躁的谦虚精神、艰苦奋斗的创业精神（西柏坡精神）</span>-->
<!--                <span>井冈山：坚定信念、艰苦奋斗，是井冈山精神的灵魂，实事求是、敢闯新路，是井冈山精神的核心，依靠群众、勇于胜利，是井冈山精神的基石，相信群众、依靠群众是井冈山精神的根本（井冈山精神）</span>-->
<!--                <span>延安：自力更生、艰苦奋斗的创业精神，全心全意为人民服务的精神，理论联系实际、不断开拓创新的精神，实事求是的思想路线（延安精神）</span>-->
<!--                <span>会宁：红军长征开始的时间是1934年10月。1936年10月22日，红一、二、四方面军在甘肃会宁会师，长征结束</span>-->
<!--                <span>娄山关：出自毛泽东诗词《忆秦娥·娄山关》</span>-->
<!--                <span>昆仑山：出自毛泽东诗词《念奴娇·昆仑》</span>-->
<!--                <span>六盘山：出自毛泽东诗词《清平乐·六盘山》</span>-->
<!--                <span>五云山：出自毛泽东诗词《七绝·五云山》</span>-->
            </p>
            <div class="department-card">
                <ul>
                    <li v-for="(item,index) in departmentCardList" :key="index">
                        <div class="wrap-department-card-content">
                            <div class="wrap-department-card-img">
                                <img :src="require('../../assets/img/'+item.img)" alt="">
                            </div>
                            <span>{{item.detail}}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data () {
            return {
                departmentCardList: [
                    {
                        img:'k1.png',
                        detail: '西柏坡：敢于斗争、敢于胜利的开拓进取精神，依靠群众和团结统一的民主精神，戒骄戒躁的谦虚精神、艰苦奋斗的创业精神（西柏坡精神）'
                    },
                    {
                        img:'k2.png',
                        detail: '井冈山：坚定信念、艰苦奋斗，是井冈山精神的灵魂，实事求是、敢闯新路，是井冈山精神的核心，依靠群众、勇于胜利，是井冈山精神的基石，相信群众、依靠群众是井冈山精神的根本（井冈山精神）'
                    },
                    {
                        img:'k3.png',
                        detail: '延安：自力更生、艰苦奋斗的创业精神，全心全意为人民服务的精神，理论联系实际、不断开拓创新的精神，实事求是的思想路线（延安精神）'
                    },
                    {
                        img:'k4.png',
                        detail: '会宁：红军长征开始的时间是1934年10月。1936年10月22日，红一、二、四方面军在甘肃会宁会师，长征结束'
                    },
                    {
                        img:'k5.png',
                        detail: '娄山关：出自毛泽东诗词《忆秦娥·娄山关》'
                    },
                    {
                        img:'k6.png',
                        detail: '昆仑山：出自毛泽东诗词《念奴娇·昆仑》'
                    },
                    {
                        img:'k7.png',
                        detail: '六盘山：出自毛泽东诗词《清平乐·六盘山》'
                    },
                    {
                        img:'k8.png',
                        detail: '五云山：出自毛泽东诗词《七绝·五云山》'
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
            this.$parent.toPage(5)
        },
    }
</script>

<style scoped>
    li {
        list-style: none;
    }
    .banner{
        height: 600px;
        overflow: hidden;
        position: relative;
    }
    .banner .mengban{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        background: rgba(0,0,0,0.5);
    }
    .mengban span{
        display: block;
        font-size: 99px;
        color: #fff;
        margin-top: 80px;
        border-bottom: 1px solid #fff;
        position: relative;
    }
    .mengban div{
        width: 1440px;
        margin: 0 auto;
    }
    .mengban span:nth-child(1){
        text-align: left;
    }
    .mengban span:nth-child(2){
        text-align: right;
    }
    .mengban span:nth-child(1)::before{
        content:'';
        width: 60px;
        height: 60px;
        border-radius: 30px;
        border: 1px solid #fff;
        position: absolute;
        left: -70px;
        bottom: -30px;
    }
    .mengban:hover span{
        width: 100%;
        transition: width 3s;
    }
    .mengban span:nth-child(2)::before{
        content:'';
        width: 60px;
        height: 60px;
        border-radius: 30px;
        border: 1px solid #fff;
        position: absolute;
        right: -70px;
        bottom: -30px;
    }
    .mengban:hover span{
        display: block;
        /*animation: rect 3s ease;*/
        /*animation-fill-mode: forwards; !*保持最后一帧的状态*!*/

    }
    @keyframes rect {
        from {
            transform: scale(0.5, 1);
        }
        to {
            transform: scale(1, 1);
        }
    }
    .banner img{
        width: 100%;
    }
    .about{
        width: 1440px;
        margin: 0 auto;
        padding: 60px 0;
    }
    p span{
        display: block;
        text-align: left;
        font-size: 18px;
        color: #666;
        line-height: 36px;
    }
    .title{
        font-size: 28px;
        color: #333!important;
        border-left:4px solid #FF6A00 ;
        padding-left: 10px;
        margin:20px 0 ;
    }
    #v1{
        width: 100%;
        opacity: 0.5;
        background: #333;
        position: relative;
        top: -160px;
    }
    .department-card {
        width: 1440px;
        margin: 0 auto;
    }
    .department-card > ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .department-card ul > li {
        min-width: 25%;
        max-width: 25%;
    }
    .wrap-department-card-content {
        height: 400px;
        margin: 10px;
        background: #FFFFFF;
    }
    .wrap-department-card-img {
        width: 95%;
        margin: 0 auto;
    }
    .wrap-department-card-img img{
        width: 100%;
    }
    .wrap-department-card-content span {
        display: block;
        margin: 10px auto;
        padding-top: 10px;
        border-top: 1px solid #ECECEC;
        font-size: 16px;
        color: #666;
    }
</style>
