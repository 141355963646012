<template>
    <div>
		<div class="banner">
			<div class="swiper-container">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(item,index) in swiperSlides" :key="index">
						<img :src="require('../../assets/img/'+item.img)" alt="">
					</div>
				</div>
				<!-- 如果需要分页器 -->
				<div class="swiper-pagination"></div>
				<!-- 如果需要导航按钮 -->
				<div class="swiper-button-prev"></div>
				<div class="swiper-button-next"></div>
			</div>
		</div>
		<div class="smart-server">
			<div class="smart-title">
				<div class="smart-f-title">
					<span>打造智慧健康</span><span style="color: #ff4100">管理服务</span>
				</div>
				<span style="text-transform: uppercase">Building health management wisdom service</span>
			</div>
			<div class="smart-content">
				<ul>
					<li
							@mouseover="tabShow.a = true"
							@mouseleave="tabShow.a = false">
						<div>
							<img
									v-show="!tabShow.a"
									src="~@/assets/img/icon_1znjk.png" alt=""/>
							<img
									v-show="tabShow.a"
									src="~@/assets/img/icon_1znjk1.png" alt=""/>
							<div class="smart-c-title">
								<span>健康服务</span>
								<span>Intelligent customer service</span>
							</div>
						</div>
					</li>
					<li
							@mouseover="tabShow.b = true"
							@mouseleave="tabShow.b = false">
						<div>
							<img
							v-show="!tabShow.b"
							src="~@/assets/img/icon_2znkf.png" alt=""/>
							<img
							v-show="tabShow.b"
							src="~@/assets/img/icon_2znkf2.png" alt=""/>
							<div class="smart-c-title">
								<span>智能客服</span>
								<span>Health services</span>
							</div>
						</div>
					</li>
					<li
							@mouseover="tabShow.c = true"
							@mouseleave="tabShow.c = false">
						<div>
							<img
								v-show="!tabShow.c"
								src="~@/assets/img/icon_3zxyl.png" alt=""/>
							<img
								v-show="tabShow.c"
								src="~@/assets/img/icon_3zxyl3.png" alt=""/>
							<div class="smart-c-title">
								<span>在线医疗</span>
								<span>Online healthcare</span>
							</div>
						</div>
					</li>
					<li
						@mouseover="tabShow.d = true"
						@mouseleave="tabShow.d = false">
						<div>
							<img
							v-show="!tabShow.d"
							src="~@/assets/img/icon_4jjjy.png" alt=""/>
							<img
							v-show="tabShow.d"
							src="~@/assets/img/icon_4jjjy4.png" alt=""/>
							<div class="smart-c-title">
								<span>紧急救援</span>
								<span>Emergency rescue</span>
							</div>
						</div>
					</li>
					<li
							@mouseover="tabShow.e = true"
							@mouseleave="tabShow.e = false">
						<div>
							<img
									v-show="!tabShow.e"
									src="~@/assets/img/icon_5ss.png" alt=""/>
							<img
									v-show="tabShow.e"
									src="~@/assets/img/icon_5ss5.png" alt=""/>
							<div class="smart-c-title">
								<span>实时</span>
								<span>eal time</span>
							</div>
						</div>
					</li>
					<li
							@mouseover="tabShow.f = true"
							@mouseleave="tabShow.f = false">
						<div>
							<img
									v-show="!tabShow.f"
									src="~@/assets/img/icon_6zn.png" alt=""/>
							<img
									v-show="tabShow.f"
									src="~@/assets/img/icon_6zn6.png" alt=""/>
							<div class="smart-c-title">
								<span>数据上传分析</span>
								<span>Data upload analysis</span>
							</div>
						</div>
					</li>
					<li
							@mouseover="tabShow.g = true"
							@mouseleave="tabShow.g = false">
						<div>
							<img
									v-show="!tabShow.g"
									src="~@/assets/img/icon_7gx.png" alt=""/>
							<img
									v-show="tabShow.g"
									src="~@/assets/img/icon_7gx7.png" alt=""/>
							<div class="smart-c-title">
								<span>高效</span>
								<span>Efficient</span>
							</div>
						</div>
					</li>
					<li
							@mouseover="tabShow.h = true"
							@mouseleave="tabShow.h = false">
						<div>
							<img
									v-show="!tabShow.h"
									src="~@/assets/img/icon_8tx.png" alt=""/>
							<img
									v-show="tabShow.h"
									src="~@/assets/img/icon_8tx8.png" alt=""/>
							<div class="smart-c-title">
								<span>高效</span>
								<span>Efficient</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="index-about" @click="RouterToAbout()">
			<div class="about_bg">
				<div class="about_min"></div>
				<div class="about_content">
					<div class="about_title">
						<div class="title_chinese">
							<span>打造智慧</span><span style="color: #ff4100">健康企业</span>
						</div>
						<span style="text-transform: uppercase">Build a smart and healthy enterprise</span>
					</div>
					<div class="about_logo"></div>
					<div class="about_text">
                        <p>坚持“以人为本”的智慧健康理念，为企业员工提供智慧健康服务，实时监测员工心率、</p>
						<p>血压、HRV疲劳程度,可实时多用户端查看，企业快速获取员工健康指数，有效预防</p>
						<p>员工伤亡发生；员工可获取健康周报以及专属医疗定制服务，有效预防疾病发生。</p>
					</div>
					<div class="learn_more">
						<a href="#">查看更多</a><span class="arr_right"></span>
					</div>
				</div>
			</div>
		</div>
		<div class="smart-device">
			<div class="smart-title">
				<div class="smart-f-title">
					<span>智能</span><span style="color: #ff4100">硬件</span>
				</div>
				<span style="text-transform: uppercase">Intelligent hardware</span>
			</div>
			<div class="smart-device-content">
				<ul>
					<li v-for="(item,index) in device" :key="index">
						<div class="wrap-device-detail">
							<div class="device-title">
								<img :src="require('../../assets/img/'+item.img)" alt="">
								<span>{{item.name}}</span>
							</div>
							<div class="device-detail">
								<h3>{{item.name}}</h3>
								<p>{{item.detail}}</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div class="honorary-certificate">
			<div class="smart-title">
				<div class="smart-f-title">
					<span>荣誉</span><span style="color: #ff4100">证书</span>
				</div>
				<span style="text-transform: uppercase">Honorary certificate</span>
			</div>
			<div class="honorary-certificate-content">
				<ul>
					<li v-for="(item,index) in honorList" :key="index">
						<div class="wrap-certificate-detail">
							<div class="wrap-certificate-img">
								<img :src="require('../../assets/img/'+item.img)" alt="">
							</div>
							<span>{{item.detail}}</span>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div class="software-works">
			<div class="smart-title">
				<div class="smart-f-title">
					<span>软著</span><span style="color: #ff4100">证书</span>
				</div>
				<span style="text-transform: uppercase">The software works</span>
			</div>
			<div class="software-works-content">
				<ul>
					<li v-for="(item,index) in softwareWorksList" :key="index">
						<div class="wrap-works-detail">
							<div class="wrap-works-img">
								<img :src="require('../../assets/img/'+item.img)" alt="">
							</div>
							<div class="works-detail">
								<img :src="require('../../assets/img/'+item.img)" alt="">
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div class="news">
			<div class="smart-title">
				<div class="smart-f-title">
					<span>新闻</span><span style="color: #ff4100">中心</span>
				</div>
				<span style="text-transform: uppercase">News Center</span>
			</div>
			<div class="news-content">
				<div class="break-news">
					<div class="break-news-detail">
						<img src="~@/assets/img/n_1.jpg" alt=""/>
						<div>
							<span class="date-format">
								<span>{{$day(breaknews.date).format('MM-DD')}}</span>
								<span>{{$day(breaknews.date).format('YYYY')}}</span>
							</span>
							<div class="detail-content">
								<span>{{breaknews.name}}</span>
								<span>{{breaknews.detail}}</span>
							</div>
						</div>
					</div>
					<img src="" alt="">
				</div>
				<div class="news-item">
					<ul>
						<li v-for="(item,index) in newslist" :key="index">
							<div>
								<div class="news-date">
									<span>{{$day(item.date).format('DD')}}</span>
									<span>{{$day(item.date).format('YYYY/MM')}}</span>
								</div>
								<div class="news-item-content">
									<span>{{item.name}}</span>
									<span>{{item.detail}}</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="news-more">
				<button @click="$router.push({path:'/news/list'})">查看更多</button>
			</div>
		</div>
		<div class="partner">
			<div class="smart-title">
				<div class="smart-f-title">
					<span>合作</span><span style="color: #ff4100">伙伴</span>
				</div>
				<span style="text-transform: uppercase">Cooperation partner</span>
			</div>
			<img src="../../assets/img/partner1.jpg" alt="">
		</div>
    </div>
</template>

<script>
	// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
	// import 'swiper/swiper.min.css'
	import Swiper from 'swiper'
	export default {
		data () {
			return {
				// 动画控制
				tabShow: {
					a: false,
					b: false,
					c: false,
					d: false,
					e: false,
					f: false,
					g: false,
					h: false
				},
				device:[
					{
						img:'device_warm.png',
						name:'暖心手环Pro',
						detail:'男女同款多功能蓝牙智能手环,实时监测体温心率睡眠和压力值并生成健康档案。'
					},
					{
						img:'pic_anxb@2x.png',
						name:'安心手表',
						detail:'安心手表是一款关于健康的智能穿戴电子产品，能够对身体健康进行实时监测，它自带定位功能，监测到您的所在位置，当需要救助时，一键SOS功能发出求救信息，随时保证您的人生安全。'
					},
					{
						img:'device_gift.png',
						name:'健康守护礼盒',
						detail:'本公司出品的健康守护礼包，含有智能手表、一体机、睡眠带、跌倒报警器和定位报警器5件智能健康监测设备,\n' +
								'配合手机APP/小程序上的定制化健康服务，实现物联网+大数据+AI智能分析对用户身体健康的监测、预测、干预、治愈的全流程管理，\n' +
								'为用户提供全方面的健康守护服务。'
					},
					{
						img:'device_fall.png',
						name:'跌倒报警器',
						detail:'24小时监测老人身体情况,一旦老人跌倒会及时发出警报,监控人员可查询老人定位进行救助。'
					},
					{
						img:'pic_nxytj@2x.png',
						name:'省心一体机',
						detail:'省心一体机是一款适合慢病监测的智能健康电子设备，能够对试验者的血压、血糖、心率、尿酸、胆固醇、甘油三酯等数据同时监测。满足慢性病群体的多项指标管理需求。'
					},
					{
						img:'device_location.png',
						name:'定位报警器',
						detail:'设定安全围栏,确定老人的安全活动范围,离开安全范围,可发出警报通知并对老人进行定位。'
					},
				],
				honorList:[
					{
						img:'honorary_certificate4.jpg',
						detail:'上海市高新技术企业认证'
					},
					{
						img:'honorary_certificate5.jpg',
						detail:'全国智能穿戴行业质量领先品牌'
					},
					{
						img:'honorary_certificate3.jpg',
						detail:'外观设计专利和实用新型专利'
					},
					{
						img:'honorary_certificate7.jpg',
						detail:'全国智慧医保解决方案大赛优胜奖'
					},
					{
						img:'honorary_certificate6.jpg',
						detail:'江苏省健康医疗大数据创新应用大赛优胜奖'
					},
				],
				softwareWorksList:[
					{
						img:'software_works1.png'
					},
					// {
					// 	img:'software_works2.png'
					// },
					{
						img:'software_works3.png'
					},
					{
						img:'software_works4.png'
					},
					// {
					// 	img:'software_works5.png'
					// },
					// {
					// 	img:'software_works6.png'
					// },
					// {
					// 	img:'software_works7.png'
					// },
					// {
					// 	img:'software_works8.png'
					// },
					{
						img:'software_works9.png'
					},
				],
				breaknews:{
					img:'',
					name:'祝贺心麦与扬州市医保局合作研讨会议圆满成功',
					date:'2023-07-25',
					detail:'失能评估项目合作讨论会议在扬州市医保局会议室召开，心麦公司领导和扬州市医保局领导均到场参加了此次会议。'
				},
				newslist:[
					{
						name:'喜报！心麦获得首届江苏省健康医疗大数据创新应用大赛优胜奖',
						date:'2023-07-18',
						detail:'首届江苏省健康医疗大数据创新应用大赛颁奖典礼在常州举行。'
					},
					{
						name:'祝贺心麦与常熟市医保局合作研讨会议圆满成功',
						date:'2023-06-29',
						detail:'失能评估项目合作讨论会议在常熟市医保局会议室召开，心麦公司领导和常熟市医保局领导均到场参加了此次会议。'
					},
					{
						name:'祝贺心麦与常州市医保局合作研讨会议圆满成功',
						date:'2023-05-15',
						detail:'失能评估项目合作讨论会议在常州市医保局会议室召开，心麦公司领导和常州市医保局领导均到场参加了此次会议。'
					},
				],
				swiperOption: {
					slidesPerView: 1,
					spaceBetween: 30,
					loop: true,
					pagination: {
						el: '.swiper-pagination',
						clickable: true // 允许点击小圆点跳转
					},
					autoplay: {
						delay: 3000,
						disableOnInteraction: true // 手动切换之后继续自动轮播
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					}
				},
				swiperSlides: [
					{
						name:'',
						img:'photo_zhutu4.png'
					},
					{
						name:'',
						img:'photo_zhutu5.png'
					},
					{
						name:'',
						img:'photo_zhutu6.png'
					},
					// {
					// 	name:'',
					// 	img:'photo_zhutu1.jpg'
					// },
					// {
					// 	name:'',
					// 	img:'photo_zhutu2.jpg'
					// },
					// {
					// 	name:'',
					// 	img:'photo_zhutu3.jpg'
					// }
				]
			}
		},
		mounted() {
			new Swiper ('.swiper-container', {
				loop: true,
				// 如果需要分页器
				pagination: '.swiper-pagination',
				// 如果需要前进后退按钮
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				// 如果需要滚动条
				// scrollbar: '.swiper-scrollbar',
				//如果需要自动切换海报
				autoplay: 3000,
			})
		},
		methods: {
			imgGetUrl (img) {
				let imgFromLocal = '../../assets/img/'+img
				return require(imgFromLocal)
			},
			onSwiper(swiper) {
				console.log(swiper);
			},
			onSlideChange() {
				console.log('slide change');
			},
			RouterToAbout () {
				this.$router.push({name:'about'})
			}
		},
	};
</script>

<style scoped>
    .banner{
		width: 100%;
	}
	.banner img{
		width: 100%;
	}
	.smart-server{
		width: 100%;
		background: #F4F5F7;
		padding-bottom: 90px;
	}
	.smart-title,.smart-content{
		width: 1440px;
		margin: 0 auto;
	}
    .smart-content ul{
		display: flex;
		flex-flow:row-reverse wrap;
	}
	.smart-content ul li{
		color: #333;
		width:25%;
	}
	.smart-content ul li > div{
		background: #fff;
		transition: 0.5s;
		color: #333;
		margin: 10px;
		border-radius: 6px;
		padding: 50px 0;
	}
	.smart-c-title span:nth-child(2){
		font-size: 12px;
		letter-spacing: 0;
	}
	.smart-content ul li:hover > div{
		background: #ff4100;
		transition: 0.7s;
		color: #fff!important;
		border-radius: 6px;
	}
	.smart-content ul li > div > div span{
		display: block;
		margin-top: 10px;
	}
	.smart-title{
		padding: 40px 0;
	}
	.smart-title > span,
	.about_title >span{
		font-size: 14px;
		color: #7F7F7F;
		letter-spacing: 0;
		display: block;
		margin-top: 10px;
	}
	.smart-f-title{
		font-size: 30px;
	}
	.about_bg{
		position: relative;
		width: 100%;
		height: 730px;
		background: url(~@/assets/img/about_bg.png) no-repeat;
		background-size: 100% 100%;
	}
	.about_min{
		width: 45%;
		height: 70%;
		margin: auto 0;
		background: rgba(255,255,255,0.70);
		transform: translateY(25%);
	}
	.about_content{
		position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 1440px;
        height: 70%;
        transform: translateY(25%);
	}
	.about_title,
	.about_text,
	.learn_more{
		padding: 20px 0 20px 8px;
		text-align: left;
	}
	.title_chinese{
		font-size: 30px;
	}
	.about_logo{
		width: 742px;
		height: 204px;
		border: 1px solid #979797;
		background: url(~@/assets/img/about_logo.png) no-repeat center center;
	}
	.about_text{
        width: 610px;
	}
	.about_text > p{
		margin: 5px 0;
		text-align: left;
		font-size: 14px;
		color: #333;
	}
	.learn_more{
		position: relative;
        width: 610px;
		text-align: left;
	}
	.learn_more > a{
        color: #333;
		font-size: 14px;
	}
	.arr_right{
        position: absolute;
        top:48%;
        left: 11%;
		width: 5px;
        height: 5px;
        border-right: 1px solid #333;
        border-bottom: 1px solid #333;
        display: block;
        transform:rotate(-45deg);
	}
	.learn_more:hover a{
		color: #FF6A00;
	}
	.learn_more:hover .arr_right{
		border-right-color: #FF6A00;
		border-bottom-color: #FF6A00;
	}
	.smart-device{
		padding: 60px 0;
	}
	.smart-device-content{
		width: 1440px;
		margin: 0px auto;
	}
	.smart-device-content ul{
		display: flex;
		flex-flow:row-reverse wrap;
	}
	.smart-device-content ul li{
		width: 33.33%;
		margin-top: 30px;
	}
	.smart-device-content ul li>div{
		background: #FFFFFF;
		border: 1px solid #ECECEC;
		margin: 20px;
		height: 400px;
		position: relative;
	}
	.device-title{
		width: 80%;
		margin: 0 auto;
		text-align: center;
	}
	.device-title img{
		width: 80%;
	}
	.device-title span{
		font-size: 24px;
		color: #333333;
		letter-spacing: 0.46px;
		display: block;
		text-align: center;
	}
	.device-detail {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		background:rgba(255,255,255,0.98);
		height: 100%;
		padding: 30px;
		overflow: hidden;
		display: none;
		transition: 1s;
	}
	.device-detail h3{
		margin: 10px 0;
		transition: 1s;
	}
	.device-detail p{
		font-size: 16px;
		color: #666666;
		letter-spacing: 0.3px;
		line-height: 32px;
		margin-top: 60px;
		transition: 1s;
	}
	.smart-device-content ul li:hover .device-detail {
		display: block;
		animation: rect 0.5s ease;
		animation-fill-mode: forwards; /*保持最后一帧的状态*/

	}
	@keyframes rect {
		from {
			transform: scale(0.5, 0.5);
			box-shadow: 1px 1px 10px rgba(0,0,0,0.10);
			top: -30px;
		}
		to {
			transform: scale(1.1, 0.99);
			box-shadow: 1px 1px 10px rgba(0,0,0,0.10);
			top: -30px;
		}
	}
	.honorary-certificate {
		padding: 60px 0;
		background-color: #F4F5F7;
	}
	.software-works {
		padding: 60px 0;
	}
	.honorary-certificate-content,
	.software-works-content {
		width: 1440px;
		margin: 0 auto;
	}
	.honorary-certificate-content ul {
		display: flex;
		flex-wrap: wrap;
	}
	.honorary-certificate-content ul>li {
		min-width: 33.3%;
		max-width: 33.3%;
		margin-top: 30px;
	}
	.honorary-certificate-content ul li>div {
		height: 400px;
		margin: 20px;
		background-color: #FFF;
		border: 1px solid #ECECEC;
	}
	.wrap-certificate-img {
		margin: 0 auto;
		width: 98%;
		height: 80%;
	}
	.wrap-certificate-img img,
	.wrap-works-img img{
		width: 80%;
		height: 80%;
		transform: translateY(15%);
	}
	.wrap-certificate-detail span {
		display: block;
		width: 80%;
		margin: 10px auto;
		font-size: 18px;
		color: #333333;
	}
	.software-works-content ul {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.software-works-content ul>li {
		min-width: 25%;
		max-width: 25%;
		margin-top: 30px;
	}
	.software-works-content ul li>div {
		position: relative;
		height: 400px;
		margin: 10px;
		background-color: #FFF;
		border: 1px solid #ECECEC;
	}
	.wrap-works-img {
		margin: 0 auto;
		width: 98%;
		height: 98%;
	}
	.works-detail {
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 100%;
		overflow: hidden;
	}
	.software-works-content ul li:hover .works-detail {
		display: block;
	}
	.news{
		background: #F4F5F7;
		padding: 60px 0;
	}
	.break-news{
		padding-right: 20px;
	}
	.news-content{
		width: 1440px;
		margin: 0 auto;
		display: flex;
	}
	.break-news-detail img{
		width: 100%;
	}
	.date-format{
		display: block;
		position: relative;
		float: left;
		width: 10%;
		margin-top: 5px;
	}
	.detail-content{
		float: left;
		width: 90%;
		text-align: left;
	}
	.date-format::after{
		content: '';
		border-left: 1px solid #666;
		width: 0px;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
	}
	.date-format span{
		display: block;
	}
	.date-format span:nth-child(1){
		font-size: 20px;
		color: #333333;
		letter-spacing: 0.38px;
		text-align: center;
		line-height: 28px;
	}
	.break-news-detail > div{
		overflow: hidden;
	}
	.detail-content span{
		display: block;
		width: 95%;
		margin: 5px auto;
	}
	.detail-content span:nth-child(1){
		font-size: 18px;
		color: #333333;
		letter-spacing: 0.34px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.detail-content span:nth-child(2){
		font-size: 14px;
		color: #333333;
		letter-spacing: 0.34px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.news-item li{
		margin-bottom: 10px;
		overflow: hidden;
		border-bottom: 1px dashed #D8D8D8;
		padding-bottom: 10px;
	}
	.news-item li > div{
		overflow: hidden;
	}
	.news-date{
		float: left;
		width: 20%;
		height: 110px;
		background: #BFBFBF;
		color: #fff;
		line-height: 110px;
	}
	.news-date span{
		display: block;
	}
	.news-date span:nth-child(1){
		font-size: 28px;
		color: #FFFFFF;
		letter-spacing: 0.46px;
		text-align: center;
		line-height: 33px;
		margin-top: 20px;
	}
	.news-date span:nth-child(2){
		font-size: 18px;
		color: #FFFFFF;
		letter-spacing: 0.46px;
		text-align: center;
		line-height: 22px;
		margin-top: 5px;
	}
	.news-item-content{
		width: 80%;
		float: left;
	}
	.news-item-content span{
		display: block;
		text-align: left;
		width: 95%;
		margin: 0 auto 5px auto;
	}
	.news-item-content span:nth-child(1){
		font-size: 18px;
		color: #333333;
		letter-spacing: 0.34px;
	}
	.news-item-content span:nth-child(2){
		font-size: 14px;
		color: #7F7F7F;
		letter-spacing: 0.27px;
		line-height: 24px;
		margin-top: 20px;
	}
	.news-item li:hover .news-date{
		background:#ff4100;
	}
	.news-item li:hover .news-item-content span:nth-child(1){
		color:#ff4100;
	}
	.break-news-detail:hover .detail-content span:nth-child(1){
		color: #ff4100;
	}
	.break-news-detail:hover img{
		opacity: 0.8;
	}
	.news-more{
		margin-top: 60px;
	}
	.news-more button{
		background: #FF6A00;
		border: none;
		width: 200px;
		height: 40px;
		color: #fff;
		font-size: 14px;
	}
	.news-more button:active{
		border: none;
	}
	.partner {
		padding: 60px 0;
	}
	.partner > img {
		width: 60%;
		margin-top: 30px;
	}
</style>
