import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Style from './assets/style/style.css' // 初始化CSS样式
import VueAwesomeSwiper from 'vue-awesome-swiper'
import DayJs from 'dayjs'
// require styles
// import 'swiper/swiper.min.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
Vue.use(VueAwesomeSwiper, /* { default global options } */)
Vue.config.productionTip = false
Vue.prototype.$day = DayJs
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
