<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>喜报！心麦成功揭榜常态化疫情防控重点任务</h2>
            <span>2021-08-31</span>
            <div class="wrap-news-content">
                <p>2021年8月28日，无锡市工业和信息化局发布公示，物联网等新一代信息技术服务支撑常态化疫情防控重点任务中，心麦成功揭榜任务十：无线智能测温设备。</p>
                <div class="wrap-news-list">
                    <div>
                        <img src="../../assets/img/news/007_1.jpg" alt="">
                    </div>
                    <span>无锡工信局揭榜公示名单(部分)</span>
                    <p>为全面落实党中央领导在江苏调研疫情防控时的讲话精神以及省委省政府最新疫情防控决策部署，充分发挥我市在新一代信息技术产业领域先发优势，全面提升我市疫情常态化科学防控能力，无锡工信局面向在锡物联网等新一代信息技术企业、科研院所等单位开展揭榜攻关，加强对重点人群、重点场所、重点领域实施常态化监管，提出高效、科学、精准的科学防控解决方案，提高公共卫生应急检测能力，构建常态化疫情防控新一代信息技术体系。
                    </p>
                    <p>在此次揭榜任务中，心麦和无锡市计量测试院、清华大学无锡应用技术研究院等共同揭榜任务十：运用物联网技术实时、持续、动态监测人体温度，突破蓝牙使用范围，远程监测测温情况，遇到体温异常情况自动提示并将信息无线传输至绑定的手机，实现体温的实时监护查看。心麦和一众国家重点企事业单位肩负使命积极响应国家号召，充分发挥自身在健康防疫-体温监测预警方面的优势。</p>
                    <p>
                        此外心麦已于去年申请一种智能感知防疫预警方法专利（专利号202011591136.6），并将该整体解决方案运用于无锡南外国王国际学校、无锡太湖学院物联网工程学院。
                    </p>
                    <div>
                        <img src="../../assets/img/news/007_2.jpg" alt="">
                    </div>
                    <p>对于此次揭榜成功，心麦缪总表示：机会是留给准备好的人的，心麦经过一年多的准备最终获得认可，而这也证明了心麦在做的正是政府和人民需要的事情，也是能解决实际问题的。心麦在细分领域有着前瞻性和引领性，并且心麦团队是能打仗并能打胜仗的。这次能够入围是给心麦全体员工的肯定，更是心麦继续前行的动力，我们一定会坚持技术驱动，做出更多更好的作品，帮助实现老百姓对美好生活的向往！</p>
                    <p>此次入围企业还包括中国电信无锡分公司、中国联通无锡分公司、灵锡互联网（无锡）有限公司、无锡市人民医院、无锡医疗物联网研究院、中电海康无锡科技有限公司、江南大学等。</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail7",
        data () {
            return {

            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center;
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
    .wrap-news-list > p {
        margin: 20px 0;
    }
</style>
