<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>心麦亮相雪浪大会</h2>
            <span>2021-06-29</span>
            <div class="wrap-news-content">
                <p>2021年6月25日-26日,心麦(江苏)大数据科技有限公司受邀参加第三届雪浪大会,并组织举办主题为“AI数字赋能健康服务”论坛及数字健康主题展。</p>
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <span>{{item.tips ? item.tips : ''}}</span>
                    <p>{{item.summary ? item.summary : ''}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail4",
        data () {
            return {
                newsList: [
                    {
                        img: '004_1.png',
                        tips: '雪浪大会心麦论坛会场外观',
                        summary: '在25日的论坛活动中，心麦邀请了诸多业内专家、学者就数字健康发展方向做主题演讲，内容涉及全过程数字化健康管理服务体系、健康数据挖掘、分析、处理、运用等。从不同角度深刻分析数字健康产业发展动向。'
                    },
                    {
                        img: '004_2.png',
                        tips: '雪浪大会心麦主题论坛现场',
                        summary: '首先，心麦总裁缪卫洪致欢迎辞，并做“AI赋能大健康，DT夯实新基建”主题演讲。心麦倡导：1、数据监测设备无感、便携性携带；2、数据监测要不间断、长周期；3、个人健康状态数字化呈现，打造人体健康指数。这样人们对自我健康管理才能形成自趋式，做到数据多跑路，服务更贴心。最终实现监测、预测、干预、治愈全过程数字化的闭环管理。'
                    },
                    {
                        img: '004_3.png',
                        tips: '心麦总裁缪卫洪',
                        summary: '江南大学医学院蒋玉宇教授就“远程AI数字化赋能长期护理保险智能化精细化管控”进行演讲。'
                    },
                    {
                        img: '004_4.png',
                        tips: '江南大学蒋玉宇',
                        summary: '北京易华录信息技术股份公司医养大数据研究所所长、高级研究员李艳梅博士就“大数据分析与挖掘典型案例”进行主题演讲。'
                    }
                    ,
                    {
                        img: '004_5.png',
                        tips: '北京易华录李艳梅',
                        summary: '上海市健康产业发展促进协会副会长俞建文老师就“大科学观下的科技与艺术和审美一体化的现实意义”进行演讲。'
                    }
                    ,
                    {
                        img: '004_6.png',
                        tips: '上海市健康产业发展促进协会副会长俞建文',
                        summary: '美中科技协会会长谢家叶博士就美国医疗服务中AI的应用现状等进行了深刻分析。'
                    }
                    ,
                    {
                        img: '004_7.png',
                        tips: '美中科技协会会长谢家叶',
                        summary: '会议中，心麦与北京易华录所属无锡数据湖信息技术有限公司在全场嘉宾见证下，签署了战略合作协议。'
                    }
                    ,
                    {
                        img: '004_8.png',
                        tips: '心麦与无锡数据湖现场签约仪式',
                        summary: '25日下午论坛结束后，缪总在心麦主题展厅中接受媒体专访，就当前健康医疗大数据存在的痛点、难点，心麦面临的新的机遇，以及未来的发展规划等方面进行了深刻阐述。缪总表示，心麦愿做一个探路者，做难而正确的事情，坚持自己的梦想。'
                    }
                    ,
                    {
                        img: '004_9.png',
                        tips: '专访摄制组准备拍摄工作',
                        summary: ''
                    }
                    ,
                    {
                        img: '004_10.png',
                        tips: '心麦总裁缪卫洪接收媒体专访',
                        summary: '26日上午，无锡市委书记黄钦与中国工程院院士王坚等多名院士一行莅临心麦主题展厅，缪总介绍了心麦基于软硬件平台建设打造数字化健康引擎，目前在智慧养老、智慧校园及智慧交通等场景提供整体解决方案，特别是在国家实施“长期护理保险”制度的过程中，心麦利用自身硬件研发及数据分析算法上的优势，助力政府相关部门更好的进行精细化管理，确保在实施过程中做到公平、公开、公正。'
                    }
                    ,
                    {
                        img: '004_11.png',
                        tips: '心麦数字健康主题展',
                        summary: ''
                    }
                    ,
                    {
                        img: '004_12.png',
                        tips: '无锡市委书记黄钦等莅临心麦主题展厅交流互动',
                        summary: '黄书记等对心麦所做的工作及目前取得的成就予以肯定，对心麦未来的发展方向给予高度认可，心麦将把握好此次大会契机，将数字健康领域进一步拓宽，为人民美好生活贡献力量。'
                    }
                    ,
                    {
                        img: '004_13.png',
                        tips: '心麦技术总监鲍健明与王坚院士合影',
                        summary: '“很遗憾今天没有穿我的格子衬衫”！心麦技术总监鲍健明抓住这难得的机会，与“偶像”王坚院士合影留念后调侃道，雪浪大会也帮助他实现了“追星”梦。'
                    }
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    };
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
