<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="wrap-news">
            <h2>喜报！心麦获得首届江苏省健康医疗大数据创新应用大赛优胜奖</h2>
            <span>2023-07-18</span>
            <div class="wrap-news-content">
                <div class="wrap-news-list" v-for="(item,index) in newsList" :key="index">
                    <div>
                        <img :src="require('../../assets/img/news/'+item.img)" alt="">
                    </div>
                    <!-- <span>{{item.tips ? item.tips : ''}}</span>
                        <p>{{item.summary ? item.summary : ''}}
                        </p> -->
                </div>
                <p>2023年7月18日，首届江苏省健康医疗大数据创新应用大赛颁奖典礼在常州举行。经过与多只队伍的激烈角逐，心麦的参赛项目《AI生态管理-大数据视角下长期护理失能评估和服务》最终脱颖而出，得到了专家评审的高度认可，获得了大赛颁发的辅助决策赛道优胜奖。此次获奖是对心麦多年来努力的肯定，也必将激烈团队向更高的荣誉迈进。</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "detail18",
        data () {
            return {
                newsList: [
                    {
                        img: '018.jpg',
                    },
                    {
                        img: '018_2.jpg',
                    },
                ]
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
        },
    }
</script>

<style scoped>
    .wrap-news {
        width: 1200px;
        margin: 0 auto
    }
    .wrap-news h2 {
        margin: 10px 0
    }
    .wrap-news-content {
        margin: 30px 0
    }
    .wrap-news-list > div {
        margin: 20px 0;
        text-align: center
    }
    .wrap-news-list > div img {
        width: 50%
    }
    .wrap-news-list > span {
        display: block;
        margin: 5px auto;
        font-size: 14px
    }
</style>
