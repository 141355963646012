<template>
    <div>
        <div class="banner">
            <img src="~@/assets/img/znyj.jpg" alt="">
        </div>
        <div class="smart-device">
            <div class="smart-title">
                <div class="smart-f-title">
                    <span>新闻</span><span style="color: #ff4100">资讯</span>
                </div>
                <span style="text-transform: uppercase">News information</span>
            </div>
            <div class="smart-device-content">
                <ul>
                    <li v-for="(item,index) in newsList" :key="index" @click="detail(item.eventIndex)">
                        <div class="wrap-device-detail">
                            <div class="device-title">
                                <img :src="require('../../assets/img/news/'+item.img)" alt="">
                                <span>{{item.name}}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                newsList:[
                    {
                        img:'019.jpg',
                        name:'祝贺心麦与扬州市医保局合作研讨会议圆满成功',
                        eventIndex:19,
                        detail:''
                    },
                    {
                        img:'018.jpg',
                        name:'喜报！心麦获得首届江苏省健康医疗大数据创新应用大赛优胜奖',
                        eventIndex:18,
                        detail:''
                    },
                    {
                        img:'017.jpg',
                        name:'祝贺心麦与常熟市医保局合作研讨会议圆满成功',
                        eventIndex:17,
                        detail:''
                    },
                    {
                        img:'016.jpg',
                        name:'祝贺心麦与常州市医保局合作研讨会议圆满成功',
                        eventIndex:16,
                        detail:''
                    },
                    {
                        img:'015.png',
                        name:'祝贺心麦与广州市医保局合作研讨会议圆满成功',
                        eventIndex:15,
                        detail:''
                    },
                    {
                        img:'014.png',
                        name:'祝贺心麦与南通市医保局合作研讨会议圆满成功',
                        eventIndex:14,
                        detail:''
                    },
                    {
                        img:'013.jpg',
                        name:'喜报！心麦获得智慧医保解决方案大赛优胜奖',
                        eventIndex:13,
                        detail:''
                    },
                    {
                        img:'012.png',
                        name:'心麦携手茁华和华为，打造智慧养老新高度！',
                        eventIndex:12,
                        detail:''
                    },
                    {
                        img:'011.jpg',
                        name:'心麦&华为携手并肩，砥砺前行，谱写新篇！',
                        eventIndex:11,
                        detail:''
                    },
                    {
                        img:'010.jpg',
                        name:'祝贺心麦与江西省康复辅具技术中心合作研讨会议圆满成功！',
                        eventIndex:10,
                        detail:''
                    },
                    {
                        img:'009.jpg',
                        name:'紧扣时代脉搏，拓展数字经济',
                        eventIndex:9,
                        detail:''
                    },
                    {
                        img:'008.jpg',
                        name:'浓浓关怀暖人心，殷殷期许勇奋进',
                        eventIndex:8,
                        detail:''
                    },
                    {
                        img:'007.jpg',
                        name:'喜报！心麦成功揭榜常态化疫情防控重点任务',
                        eventIndex:7,
                        detail:''
                    },
                    {
                        img:'006_ps.jpg',
                        name:'心麦2021上半年工作总结大会圆满结束',
                        eventIndex:6,
                        detail:''
                    },
                    {
                        img:'005_ps.jpg',
                        name:'心麦员工庆祝建党100周年',
                        eventIndex:5,
                        detail:''
                    },
                    {
                        img:'004_1.png',
                        name:'心麦亮相雪浪大会',
                        eventIndex:4,
                        detail:''
                    },
                    {
                        img:'003_ps.jpg',
                        name:'热烈欢迎无锡医保中心各位领导莅临心麦指导工作',
                        eventIndex:3,
                        detail:''
                    },
                    {
                        img:'002_ps.jpg',
                        eventIndex:2,
                        name:'热烈欢迎南外国王国际学校尤小艳校长一行莅临心麦',
                        detail:''
                    },
                    {
                        img:'001_ps.jpg',
                        name:'华为技术有限公司企业BG全球副总裁薛铭莅临心麦',
                        eventIndex:1,
                        detail:''
                    },
                ],
            }
        },
        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop =0; //回到顶部
            this.$parent.$parent.toPage(3)
        },
        methods: {
            detail(index){
                switch(index) {
                    case 1:
                        this.$router.push({path:'/news/detail1'})
                        break;
                    case 2:
                        this.$router.push({path:'/news/detail2'})
                        break;
                    case 3:
                        this.$router.push({path:'/news/detail3'})
                        break;
                    case 4:
                        this.$router.push({path:'/news/detail4'})
                        break;
                    case 5:
                        this.$router.push({path:'/news/detail5'})
                        break;
                    case 6:
                        this.$router.push({path:'/news/detail6'})
                        break;
                    case 7:
                        this.$router.push({path:'/news/detail7'})
                        break;
                    case 8:
                        this.$router.push({path:'/news/detail8'})
                        break;
                    case 9:
                        this.$router.push({path:'/news/detail9'})
                        break;
                    case 10:
                        this.$router.push({path:'/news/detail10'})
                        break;
                    case 11:
                        this.$router.push({path:'/news/detail11'})
                        break;
                    case 12:
                        this.$router.push({path:'/news/detail12'})
                }
                if (index > 12) {
                    this.$router.push({path:'/news/detail' + index})
                }
            }
        },
    };
</script>

<style scoped>
    .banner{
        width: 100%;
    }
    .banner img{
        width: 100%;
    }
    .smart-server{
        width: 100%;
        background: #F4F5F7;
        padding-bottom: 90px;
    }
    .smart-title,.smart-content{
        width: 1440px;
        margin: 0 auto;
    }
    .smart-content ul{
        display: flex;
        flex-flow:row-reverse wrap;
    }
    .smart-content ul li{
        color: #333;
        width:25%;
    }
    .smart-content ul li > div{
        background: #fff;
        transition: 0.5s;
        color: #333;
        margin: 10px;
        border-radius: 6px;
        padding: 50px 0;
    }
    .smart-c-title span:nth-child(2){
        font-size: 12px;
        letter-spacing: 0;
    }
    .smart-content ul li:hover > div{
        background: #ff4100;
        transition: 0.7s;
        color: #fff!important;
        border-radius: 6px;
    }
    .smart-content ul li > div > div span{
        display: block;
        margin-top: 10px;
    }
    .smart-title{
        padding: 40px 0;
    }
    .smart-title > span{
        font-size: 14px;
        color: #7F7F7F;
        letter-spacing: 0;
        display: block;
        margin-top: 10px;
    }
    .smart-f-title{
        font-size: 30px;
    }
    .index-about img{
        width: 100%;
    }
    .smart-device{
        padding: 60px 0;
    }
    .smart-device-content{
        width: 1440px;
        margin: 0px auto;
    }
    .smart-device-content ul{
        display: flex;
        flex-wrap: wrap;
    }
    .smart-device-content ul li{
        width: 33.33%;
        margin-top: 30px;
    }
    .smart-device-content ul li>div{
        background: #FFFFFF;
        border: 1px solid #ECECEC;
        margin: 20px;
        position: relative;
    }
    .device-title{
        width: 100%;
        margin: 0 auto;
    }
    .device-title img{
        width: 100%;
        height: 287px;
    }
    .device-title span{
        font-size: 20px;
        color: #333333;
        letter-spacing: 0.46px;
        display: block;
        text-align: left;
        width: 95%;
        margin: 10px auto;
        height: 55px;
        overflow: hidden;
    }
    .device-detail{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background:rgba(255,255,255,0.98);
        height: 100%;
        padding: 30px;
        overflow: hidden;
        display: none;
        transition: 1s;
    }
    .device-detail h3{
        margin: 10px 0;
        transition: 1s;
    }
    .device-detail p{
        font-size: 16px;
        color: #666666;
        letter-spacing: 0.3px;
        line-height: 32px;
        margin-top: 60px;
        transition: 1s;
    }
    .wrap-device-detail:hover{
        background: #ECECEC;
    }
</style>
